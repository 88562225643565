<template>
  <div>

    <Type
        v-model="form.commitmentType"
        :errors-post="errorsPost.commitmentType"
        :object-id="objectId"
        :no-save="noSave"
    />

    <Amount
        v-model="form.commitmentAmount"
        :errors-post="errorsPost.commitmentAmount"
        :object-id="objectId"
        :no-save="noSave"
    />

    <Responsibility
        v-model="form.clients"
        :errors-post="errorsPost.clients"
        :object-id="objectId"
        :no-save="noSave"
        :other="form.owner_other"
        @other="form.owner_other=$event"
    />

    <ResponsibilityOtherDetails
        v-if="form.owner_other"
        v-model="form.owner_other_details"
        :errors-post="errorsPost.owner_other_details"
        :object-id="objectId"
        :no-save="noSave"
    />

    <Notes
        v-model="form.commitmentNotes"
        :errors-post="errorsPost.commitmentNotes"
        :object-id="objectId"
        :no-save="noSave"
    />

  </div>
</template>

<script>

import Responsibility from "../../../question/questions/clientCommitment/Responsibility";
import ResponsibilityOtherDetails from "../../../question/questions/clientCommitment/ResponsibilityOtherDetails";
import Type from "../../../question/questions/clientCommitment/Type";
import Amount from "../../../question/questions/clientCommitment/Amount";
import Notes from "../../../question/questions/clientCommitment/Notes";

export default {
  name: 'CommitmentFull',
  components: {
    Type,
    Amount,
    Notes,
    ResponsibilityOtherDetails, 
    Responsibility
  },
  props: {
    value: {
      type: Object,
      required: true
    },
    objectId: {
      type: Number,
      required: false
    },
    noSave: {
      type: Boolean,
      default: false
    },
    errorsPost: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  computed: {
    form: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    }
  }
}
</script>