<template>
  <div class="fieldset">
    <h3 class="fieldset-heading">Income</h3>

    <PrimaryEmployment
        v-model="form.profilePrimaryEmployment"
        :disabled="!!form.income_primary && !!form.income_primary.length"
        @input="setEmploymentForm('primaryIncome', 'profilePrimaryEmployment')"
    />

    <template v-if="showPrimaryEmploymentDetails">
      <div class="question-box with-arrow">
        <Income
            ref="primaryIncome"
            v-model="form.income_primary"
            :showAddButton="form.income_primary && form.income_primary.length < 1"
            :addButtonText="'Add Primary Income Details'"
            :initialIncomeType="form.profilePrimaryEmployment"
            :initialIncomeClient="$store.getters.client.id"
            :disabledIncomeInput="!!form.profilePrimaryEmployment"
            :disabledIncomeClients="true"
            save-field="income_primary"
            category="primary"
            title="Please detail your primary income"
        />
      </div>

      <template v-if="form.income_primary && form.income_primary.length">
        <SecondaryEmploymentYN
            v-model="form.profileSecondaryEmploymentYN"
        />

        <SecondaryEmployment
            v-if="form.profileSecondaryEmploymentYN"
            v-model="form.profileSecondaryEmployment"
            :disabled="!!form.income_secondary && !!form.income_secondary.length"
            @input="setEmploymentForm('secondaryIncome', 'profileSecondaryEmployment')"
        />

        <div v-if="form.profileSecondaryEmploymentYN && form.profileSecondaryEmployment" class="question-box">
          <Income
              ref="secondaryIncome"
              v-model="form.income_secondary"
              :showAddButton="form.income_secondary && form.income_secondary.length < 1"
              :addButtonText="'Add Secondary Income Details'"
              :initial-income-type="form.profileSecondaryEmployment"
              :initialIncomeClient="$store.getters.client.id"
              :disabledIncomeInput="!!form.profileSecondaryEmployment"
              :disabledIncomeClients="true"
              save-field="income_secondary"
              category="secondary"
              title="Please detail your secondary income"
          />
        </div>


        <OtherIncomeYN v-model="form.profileIncomeOther"/>

        <div v-if="form.profileIncomeOther" class="question-box">
          <Income
              v-model="form.income_other"
              :showAddButton="true"
              :initialIncomeClient="$store.getters.client.id"
              :disabledIncomeClients="false"
              save-field="income_other"
              category="other"
              title="Please list any other sources of income"/>
        </div>

        <hr>

        <RetirementAge
            v-if="showPrimaryEmploymentDetails"
            v-model="form.profileRetirementAge"/>

        <IncomeFutureChangesYN v-model="form.profileIncomeFutureChanges"/>

        <div v-if="form.profileIncomeFutureChanges" class="question-box with-arrow">
          <IncomeChangeDetails v-model="form.profileIncomeChangeDetails"/>
        </div>

      </template>


    </template>


    <hr>

    <NationalInsuranceNumber v-model="form.profileNINumber"/>


  </div>
</template>

<script>
import {fieldsetHelpers} from "../fieldsetHelpers";
import RetirementAge from "@/components/common/questionnaires/question/questions/clientProfile/income/RetirementAge";
import NationalInsuranceNumber
  from "@/components/common/questionnaires/question/questions/clientProfile/income/NationalInsuranceNumber";
import IncomeFutureChangesYN
  from "@/components/common/questionnaires/question/questions/clientProfile/income/IncomeFutureChangesYN";
import IncomeChangeDetails
  from "@/components/common/questionnaires/question/questions/clientProfile/income/IncomeChangeDetails";
import PrimaryEmployment
  from "@/components/common/questionnaires/question/questions/clientProfile/income/PrimaryEmployment";
import SecondaryEmploymentYN
  from "@/components/common/questionnaires/question/questions/clientProfile/income/SecondaryEmploymentYN";
import SecondaryEmployment
  from "@/components/common/questionnaires/question/questions/clientProfile/income/SecondaryEmployment";
import OtherIncomeYN from "@/components/common/questionnaires/question/questions/clientProfile/income/OtherIncomeYN";
import Income from "@/components/common/questionnaires/question/questions/clientProfile/income/Income";

export default {
  name: 'IncomeMortgage',
  mixins: [fieldsetHelpers],
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  components: {
    Income,
    OtherIncomeYN,
    SecondaryEmployment,
    SecondaryEmploymentYN,
    PrimaryEmployment, IncomeChangeDetails, IncomeFutureChangesYN, NationalInsuranceNumber, RetirementAge
  },
  data() {
    return {
      label: 'Income', // used for steps progress
    }
  },
  computed: {
    form: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    },
    showPrimaryEmploymentDetails() {
      let inWork = [
        'Employed',
        'Contractor',
        'Company Director',
        'Sole Trader',
        'Partnership',
        'Limited Liability Partnership'
      ]
      return !!this.form.profilePrimaryEmployment && inWork.includes(this.form.profilePrimaryEmployment)
    }
  },
  methods: {
    setEmploymentForm(ref, formValue) {
      this.$nextTick(() => {
        if (this.form[formValue]) {
          this.$refs[ref].add()
        }
      })
    }
  }
};
</script>
