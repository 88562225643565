<template>
  <div>

    <DirectorSalaryLatest
        v-model="form.incomeDirectorSalaryLatest"
        :errors-post="errorsPost.incomeDirectorSalaryLatest"
        :object-id="objectId"
        :no-save="noSave"
    />

    <DirectorDividendsLatest
        v-model="form.incomeDirectorDividendsLatest"
        :errors-post="errorsPost.incomeDirectorDividendsLatest"
        :object-id="objectId"
        :no-save="noSave"
    />

    <DirectorSalaryPrevious
        v-model="form.incomeDirectorSalaryPrevious"
        :errors-post="errorsPost.incomeDirectorSalaryPrevious"
        :object-id="objectId"
        :no-save="noSave"
    />

    <DirectorDividendsPrevious
        v-model="form.incomeDirectorDividendsPrevious"
        :errors-post="errorsPost.incomeDirectorDividendsPrevious"
        :object-id="objectId"
        :no-save="noSave"
    />

    <DirectorEstablished
        v-model="form.incomeDirectorEstablished"
        :errors-post="errorsPost.incomeDirectorEstablished"
        :object-id="objectId"
        :no-save="noSave"
    />

    <DirectorShareholding
        v-model="form.incomeDirectorShareholding"
        :errors-post="errorsPost.incomeDirectorShareholding"
        :object-id="objectId"
        :no-save="noSave"
    />

    <hr>

    <CompanyName
        v-model="form.incomeCommonBusinessName"
        :errors-post="errorsPost.incomeCommonBusinessName"
        :object-id="objectId"
        :no-save="noSave"
    />

    <BusinessAddress
        v-model="form.incomeCommonBusinessAddress"
        :form="form"
        :errors-post="errorsPost.incomeCommonBusinessAddress"
        :object-id="objectId"
        :no-save="noSave"
        title="Company address"
    />

    <EmployerSector
        v-model="form.incomeCommonBusinessSector"
        :errors-post="errorsPost.incomeCommonBusinessSector"
        :object-id="objectId"
        :no-save="noSave"
    />

  </div>
</template>

<script>
import BusinessAddress from "@/components/common/questionnaires/question/questions/clientIncome/BusinessAddress";
import DirectorSalaryLatest
  from "@/components/common/questionnaires/question/questions/clientIncome/DirectorSalaryLatest";
import DirectorDividendsLatest
  from "@/components/common/questionnaires/question/questions/clientIncome/DirectorDividendsLatest";
import CompanyName from "@/components/common/questionnaires/question/questions/clientPerson/professional/CompanyName";
import DirectorShareholding
  from "@/components/common/questionnaires/question/questions/clientIncome/DirectorShareholding";
import DirectorEstablished
  from "@/components/common/questionnaires/question/questions/clientIncome/DirectorEstablished";
import DirectorSalaryPrevious
  from "@/components/common/questionnaires/question/questions/clientIncome/DirectorSalaryPrevious";
import DirectorDividendsPrevious
  from "@/components/common/questionnaires/question/questions/clientIncome/DirectorDividendsPrevious";
import EmployerSector from "@/components/common/questionnaires/question/questions/clientIncome/EmployerSector";

export default {
  name: 'CompanyDirector',
  components: {
    EmployerSector,
    DirectorDividendsPrevious,
    DirectorSalaryPrevious,
    DirectorEstablished,
    DirectorShareholding,
    CompanyName,
    DirectorDividendsLatest,
    DirectorSalaryLatest,
    BusinessAddress,

  },
  props: {
    value: {
      type: Object,
      required: true
    },
    objectId: {
      type: Number,
      required: false
    },
    noSave: {
      type: Boolean,
      default: false
    },
    errorsPost: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
    }
  },
  computed: {
    form: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    }
  }
}

</script>
