<template>
  <QuestionBase :errors="[...errorsPost, ...serverErrors, ...jsErrors]"
                :question="question"
                :valid="data !== null">
    <InputRadio v-model="data"
                :args="args"
                :class="'radio-options-wide'"
                :db="db"
                :errors-post="errorsPost"
                :js-errors="jsErrors"
                :no-save="noSave"
                :options="assetTenureOptions"
                :skipped="skipped"
                @jsErrors="jsErrors=$event"
                @serverErrors="serverErrors=$event"
                @skipped="skipped=$event" />
  </QuestionBase>
</template>

<script>
import {questionLoadHelpers} from "../../helpers/questionLoadHelpers";
import {assetOptions} from "../../options/assetOptions";

import QuestionBase from "../../QuestionBase";
import InputRadio from "@/components/common/questionnaires/question/inputs/InputRadio";

export default {
  name: 'Tenure',
  mixins: [
    questionLoadHelpers,
    assetOptions
  ],
  components: {
    InputRadio,
    QuestionBase
  },
  props: {
    value: {
      type: String,
      required: false
    },
    errorsPost: {
      type: Array,
      default: () => {
        return []
      }
    },
    noSave: {
      type: Boolean,
      default: false
    },
    objectId: {
      type: Number,
      required: false
    },
  },
  computed: {
    data: {
      set(value) {
        this.$emit('input',
            value)
      },
      get() {
        return this.value
      }
    }
  },
  data() {
    return {
      question: {
        title: 'What is the property Tenure?',
        subTitle: 'Most people own property as \'Joint Tenants\', this means if one person passes away it belongs to the other person. The alternative is \'Tenancy in Common\', this is where you own a defined share each, this might be 60/40 for example. If you are unsure you are probably Joint Tenants.',
        tip: null
      },
      db: {
        saveLocation: 'client_asset',
        saveField: 'assetTenure',
        objectId: this.objectId
      },
      args: {
        taggable: true,
        label: null,
        placeholder: 'Please select..',
        skippable: true,
        required: false,
        inputClass: 'field-75'
      },
      serverErrors: [],
      jsErrors: [],
      skipped: false,
    }
  }
}
</script>