<template>
  <div>

    <Balance
        v-model="form.liabilityBalance"
        :errors-post="errorsPost.liabilityBalance"
        :object-id="objectId"
        :no-save="noSave"
    />

    <Payment
        v-model="form.liabilityPayment"
        :errors-post="errorsPost.liabilityPayment"
        :object-id="objectId"
        :no-save="noSave"
    />

    <Responsibility
        v-model="form.clients"
        :errors-post="errorsPost.clients"
        :object-id="objectId"
        :no-save="noSave"
        :other="form.owner_other"
        @other="form.owner_other=$event"
    />

    <ResponsibilityOtherDetails
        v-if="form.owner_other"
        v-model="form.owner_other_details"
        :errors-post="errorsPost.owner_other_details"
        :object-id="objectId"
        :no-save="noSave"
    />
  </div>
</template>

<script>
import Responsibility from "../../../../question/questions/clientLiability/Responsibility";
import ResponsibilityOtherDetails from "../../../../question/questions/clientAsset/OwnerOtherDetails";
import Balance from "../../../../question/questions/clientLiability/Balance";
import Payment from "../../../../question/questions/clientLiability/Payment";

export default {
  name: 'LiabilityCreditCardEstatePlanning',
  components: {
    Payment,
     Balance, ResponsibilityOtherDetails, Responsibility},
  props: {
    value: {
      type: Object,
      required: true
    },
    objectId: {
      type: Number,
      required: false
    },
    noSave: {
      type: Boolean,
      default: false
    },
    errorsPost: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  computed: {
    form: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    }
  }
}
</script>