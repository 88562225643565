<template>
  <div class="fieldset">
    <h3 class="fieldset-heading">Name</h3>

    <Title v-model="form.profileNameTitle"/>
    <FullName :value-first="form.profileNameFirst"
              :value-middle="form.profileNameMiddle"
              :value-last="form.profileNameLast"
              @inputFirst="form.profileNameFirst=$event"
              @inputMiddle="form.profileNameMiddle=$event"
              @inputLast="form.profileNameLast=$event"/>

    <PreferredNameYN v-model="form.profilePreferredNameYN"/>
    <div v-if="form.profilePreferredNameYN" class="question-box with-arrow">
      <PreferredName v-model="form.profilePreferredName"/>
    </div>

    <PreviousNameYN v-model="form.profilePreviousNameYN"/>
    <div v-if="form.profilePreviousNameYN" class="question-box with-arrow">
      <PreviousName v-model="form.profilePreviousName"/>
      <PreviousNameDate v-model="form.profilePreviousNameChangedDate"/>
      <PreviousNameMethod v-model="form.profilePreviousNameChangedMethod"/>
    </div>

    <h3 class="fieldset-heading">Status</h3>

    <Gender v-model="form.profileSex"/>
    <Dob v-model="form.profileDOB"/>

    <Nationality v-model="form.profileNationality"/>
    <div v-if="form.profileNationality && !['British'].includes(form.profileNationality)" class="question-box with-arrow">
      <IndefiniteLeave v-model="form.profileIndefiniteLeave"/>
      <template v-if="form.profileIndefiniteLeave === false">
        <VisaType v-model="form.profileVisaType"/>
        <UKSince v-model="form.profileUKSince"/>
        <VisaExpiry v-model="form.profileVisaExpiry"/>
      </template>
    </div>

    <DiplomaticImmunity v-model="form.profileDiplomaticImmunity"/>

  </div>
</template>

<script>
import {fieldsetHelpers} from "../fieldsetHelpers";
import { statusOptions } from "../../question/options/statusOptions";

import Title from "../../question/questions/clientProfile/name/Title";
import FullName from "../../question/questions/clientProfile/name/FullName";
import DiplomaticImmunity from "../../question/questions/clientProfile/status/DiplomaticImmunity";
import Nationality from "../../question/questions/clientProfile/status/Nationality";
import Dob from "../../question/questions/clientProfile/status/Dob";
import Gender from "../../question/questions/clientProfile/status/Gender";
import PreviousName from "../../question/questions/clientProfile/name/PreviousName";
import PreviousNameYN from "../../question/questions/clientProfile/name/PreviousNameYN";
import PreviousNameDate from "../../question/questions/clientProfile/name/PreviousNameDate";
import PreviousNameMethod from "../../question/questions/clientProfile/name/PreviousNameMethod";
import PreferredNameYN from "../../question/questions/clientProfile/name/PreferredNameYN";
import PreferredName from "../../question/questions/clientProfile/name/PreferredName";
import IndefiniteLeave from "../../question/questions/clientProfile/status/IndefiniteLeave";
import UKSince from "../../question/questions/clientProfile/status/UKSince";
import VisaExpiry from "../../question/questions/clientProfile/status/VisaExpiry";
import VisaType from "@/components/common/questionnaires/question/questions/clientProfile/status/VisaType";

export default {
  name: 'NameStatusMortgage',
  mixins: [fieldsetHelpers, statusOptions],
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  components: {
    VisaType,
    VisaExpiry,
    UKSince,
    IndefiniteLeave,
    PreferredName,
    PreferredNameYN,
    PreviousNameMethod,
    PreviousNameDate,
    PreviousNameYN,
    PreviousName,
    Gender,
    Dob,
    Nationality,
    DiplomaticImmunity,
    FullName,
    Title
  },
  data() {
    return {
      label: 'Name & Status', // used for steps progress
      videoUrl: 'https://dm-kernel-public.s3.eu-west-2.amazonaws.com/static/video/kernel_help/your_info.mp4'

    }
  },
  computed: {
    form: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    }
  }
};
</script>
