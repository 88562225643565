<template>
  <QuestionBase :id="db.saveField" :errors="[...jsErrors]" :question="question" :valid="!!data.length">
    <InputRadioMultiple
        v-model="policiesMainInput"
        :db="db"
        :no-save="true"
        :options="policiesMainOptions"
        class="radio-options-wide"
        @added="setForm"
    />

    <div
        v-if="dataFiltered.length || forms.lifeCover || forms.criticalIllness || forms.lifeAndCriticalIllness ||
        forms.wholeOfLife || forms.incomeProtection"
        class="question-box with-arrow mt-32">
      <transition name="fade">
        <div class="accordion sub-items">

          <ObjectCard
              v-for="(policy, index) in dataFiltered"
              :id="'policy-main' + index"
              :key="'policy-main' + policy.id"
              :ref="'policy-main' + index"
              v-model="dataFiltered[index]"
              :title="`${policy.policyType}`"
              type="policy"
              :loading="loading"
              @delete="deleteObject(policy.id, () => {removeFromMainInput(policy.policyType)})"
              @save="saveObject(null, 'policy-main' + index)"
          >
            <PolicyMainFull :key="'policy-form' + policy.id" v-model="dataFiltered[index]" :objectId="policy.id"/>
          </ObjectCard>

          <ObjectCard
              v-if="forms.lifeCover"
              ref="policy-add-lifeCover"
              v-model="forms.lifeCover"
              title="Enter Life Cover details"
              type="policy-add"
              :loading="loading"
              @delete="removeForm('lifeCover')"
              @save="saveObject(forms.lifeCover, 'policy-add-lifeCover', null, 'lifeCover')"
          >
            <Component :is="formType" v-model="forms.lifeCover" :errors-post="errorsPost.lifeCover" :noSave="true" :showType="false"/>
          </ObjectCard>

          <ObjectCard
              v-if="forms.criticalIllness"
              ref="policy-add-criticalIllness"
              v-model="forms.criticalIllness"
              title="Enter Critical Illness details"
              type="policy-add"
              :loading="loading"
              @delete="removeForm('criticalIllness')"
              @save="saveObject(forms.criticalIllness, 'policy-add-criticalIllness', null, 'criticalIllness')"
          >
            <Component :is="formType" v-model="forms.criticalIllness" :errors-post="errorsPost.criticalIllness" :noSave="true" :showType="false"/>

          </ObjectCard>

          <ObjectCard
              v-if="forms.lifeAndCriticalIllness"
              ref="policy-add-lifeAndCriticalIllness"
              v-model="forms.lifeAndCriticalIllness"
              title="Enter Life & Critical Illness details"
              type="policy-add"
              :loading="loading"
              @delete="removeForm('lifeAndCriticalIllness')"
              @save="saveObject(forms.lifeAndCriticalIllness, 'policy-add-lifeAndCriticalIllness', null, 'lifeAndCriticalIllness')"
          >
            <Component :is="formType" v-model="forms.lifeAndCriticalIllness" :errors-post="errorsPost.lifeAndCriticalIllness" :noSave="true" :showType="false"/>

          </ObjectCard>

          <ObjectCard
              v-if="forms.wholeOfLife"
              ref="policy-add-wholeOfLife"
              v-model="forms.wholeOfLife"
              title="Enter Whole of Life details"
              type="policy-add"
              :loading="loading"
              @delete="removeForm('wholeOfLife')"
              @save="saveObject(forms.wholeOfLife, 'policy-add-wholeOfLife', null, 'wholeOfLife')"
          >
            <Component :is="formType" v-model="forms.wholeOfLife" :errors-post="errorsPost.wholeOfLife" :noSave="true" :showType="false"/>
          </ObjectCard>

          <ObjectCard
              v-if="forms.incomeProtection"
              ref="policy-add-incomeProtection"
              v-model="forms.incomeProtection"
              title="Enter Income Protection details"
              type="policy-add"
              :loading="loading"
              @delete="removeForm('incomeProtection')"
              @save="saveObject(forms.incomeProtection, 'policy-add-incomeProtection', null, 'incomeProtection')"
          >
            <Component :is="formType" v-model="forms.incomeProtection" :errors-post="errorsPost.incomeProtection" :noSave="true" :showType="false"/>

          </ObjectCard>

        </div>
      </transition>
    </div>
  </QuestionBase>

</template>

<script>
import {policyOptions} from "../../../options/policyOptions";
import {objectSaveHelpers} from "../../objects/objectSaveHelpers";
import {questionLoadHelpers} from "../../../helpers/questionLoadHelpers";
import {blankForms} from "../../../helpers/blankForms";

import QuestionBase from "../../../QuestionBase";
import ObjectCard from "../../objects/ObjectCard";
import InputRadioMultiple from "../../../inputs/InputRadioMutiple";
import PolicyMainFull from "../../../../fieldsets/sub/policy/PolicyMainFull";
import PolicyMainShort from "../../../../fieldsets/sub/policy/PolicyMainShort";
import PolicyMortgage from "../../../../fieldsets/sub/policy/PolicyMortgage";
import PolicyEstatePlanning from "../../../../fieldsets/sub/policy/PolicyEstatePlanning";

export default {
  name: 'PoliciesMain',
  components: {
    PolicyEstatePlanning,
    PolicyMortgage, PolicyMainShort, PolicyMainFull, InputRadioMultiple, ObjectCard, QuestionBase},
  mixins: [objectSaveHelpers, questionLoadHelpers, policyOptions, blankForms],
  props: {
    value: {
      type: Array,
      required: false
    },
    type: {
      type: String,
      default: 'full'
    },
    title: {
      type: String,
      default: 'Which of the below types of insurance do you have?'
    },
    subTitle: {
      type: String,
      default: 'These are just the most common types of insurance, you can add other types of insurance in the next section.'
    },
    tip: {
      type: String,
      default: null
    }
  },
  mounted() {
    this.setPolicyRadioInput()
  },
  data() {
    return {
      question: {
        title: this.title,
        subTitle: this.subTitle,
        tip: this.tip,
      },
      db: {
        saveLocation: 'client_policy',
        saveField: 'policies_main',
        additionalProgressValidationField: '',
        deleteLocation: 'client_policy',
        loadLocation: 'client_policies_main',
        syncMultipleObjectOnSave: true,
        fetchObjectsOnSave: false
      },
      args: {
        skippable: false,
        required: false,
        customErrorMessageNewOpenForm: "Save policy before proceeding, by clicking 'confirm'"
      },
      serverErrors: [],
      errorsPost: {
        lifeCover: {},
        criticalIllness: {},
        lifeAndCriticalIllness: {},
        wholeOfLife: {},
        incomeProtection: {},
      },
      jsErrors: [],
      skipped: false,
      forms: { // forms and not form: this is required for open form validation
        lifeCover: null,
        criticalIllness: null,
        lifeAndCriticalIllness: null,
        wholeOfLife: null,
        incomeProtection: null,
      },
      policiesMainInput: [],
      errors: {}
    }
  },
  computed: {
    data: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    },
    dataFiltered () {
      // remove dummy assets ('None' is added to trick progressHelpers into thinking a policy has been added)
      return this.data.filter(ob => ob !== 'None')
    },
    formType () {
      if (this.type === 'mortgage') return 'PolicyMortgage'
      if (this.type === 'estatePlanning') return 'PolicyEstatePlanning'
      return 'PolicyMainShort'
    }
  },
  methods: {
    setForm(policyType) {
      if (this.policiesMainInput.includes(policyType)) {
        if (policyType === 'Life Cover') {
          this.forms.lifeCover = this.clone(this.policyBlankForm)
          this.forms.lifeCover.policyType = 'Life Cover'
        } else if (policyType === 'Critical Illness') {
          this.forms.criticalIllness = this.clone(this.policyBlankForm)
          this.forms.criticalIllness.policyType = 'Critical Illness'
        } else if (policyType === 'Life & Critical Illness') {
          this.forms.lifeAndCriticalIllness = this.clone(this.policyBlankForm)
          this.forms.lifeAndCriticalIllness.policyType = 'Life & Critical Illness'
        } else if (policyType === 'Whole of Life') {
          this.forms.wholeOfLife = this.clone(this.policyBlankForm)
          this.forms.wholeOfLife.policyType = 'Whole of Life'
        } else if (policyType === 'Income Protection') {
          this.forms.incomeProtection = this.clone(this.policyBlankForm)
          this.forms.incomeProtection.policyType = 'Income Protection'
        }
      } else {
        if (policyType === 'Life Cover') {
          this.forms.lifeCover = null
        } else if (policyType === 'Critical Illness') {
          this.forms.criticalIllness = null
        } else if (policyType === 'Life & Critical Illness') {
          this.forms.lifeAndCriticalIllness = null
        } else if (policyType === 'Whole of Life') {
          this.forms.wholeOfLife = null
        } else if (policyType === 'Income Protection') {
          this.forms.incomeProtection = null
        }
      }
    },
    removeForm(value) {
      this.forms[value] = null
      this.errorsPost[value] = {}

      if (value === 'lifeCover') {
        this.removeFromMainInput('Life Cover')
      } else if (value === 'criticalIllness') {
        this.removeFromMainInput('Critical Illness')
      } else if (value === 'lifeAndCriticalIllness') {
        this.removeFromMainInput('Life & Critical Illness')
      } else if (value === 'wholeOfLife') {
        this.removeFromMainInput('Whole of Life')
      } else if (value === 'incomeProtection') {
        this.removeFromMainInput('Income Protection')
      }
    },
    setPolicyRadioInput() {
      for (let i = 0; i < this.policiesMainOptions.length; i++) {
        if (this.data.find(policy => policy.policyType === this.policiesMainOptions[i].value)) {
          this.policiesMainInput.push(this.policiesMainOptions[i].value)
        }
      }
    },
    removeFromMainInput(value) {
      let index = this.policiesMainInput.findIndex(option => option === value)
      if (index >= 0) this.policiesMainInput.splice(index, 1)
    }
  }
}
</script>
