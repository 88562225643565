<template>
  <div
      class="question-multipart-wrapper d-flex flex-wrap align-items-end"
  >
    <QuestionBase
        :question="question"
        :valid="!!data"
        :errors="[...serverErrors, ...jsErrors, ...errorsPost]"
    >
      <InputDateSplit
          v-if="data || data === null"
          v-model="data" :db="db"
          :args="args"
          :skipped="skipped"
          :no-save="noSave"
          @skipped="skipped=$event"
          @serverErrors="serverErrors=$event"
          @jsErrors="jsErrors=$event"
      />
    </QuestionBase>
  </div>
</template>

<script>
import { questionLoadHelpers } from "../../helpers/questionLoadHelpers";
import {saveHelpers} from "@/views/questionnaires/saveHelpers";

import QuestionBase from "../../QuestionBase";
import InputDateSplit from "../../inputs/InputDateSplit";

export default {
  name: 'DirectorEstablished',
  components: {
    InputDateSplit,
    QuestionBase
  },
  mixins: [saveHelpers, questionLoadHelpers],
  props: {
    value: {
      type: String,
      required: false
    },
    noSave: {
      type: Boolean,
      default: false
    },
    errorsPost: {
      type: Array,
      default: () => {
        return []
      }
    },
    objectId: {
      type: Number,
      required: false
    },
  },
  computed: {
    data: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    }
  },
  data() {
    return {
      question: {
        title:  'When was the company established?',
        subTitle:  'If you cannot remember exactly, an approximate date will suffice.',
        tip: null
      },
      db: {
        saveLocation: 'client_income',
        saveField: 'incomeDirectorEstablished',
        objectId: this.objectId
      },
      args: {
        skippable: false,
        required: true,
      },
      serverErrors: [],
      jsErrors: [],
      skipped: false,
    }
  }
}
</script>
