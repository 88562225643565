<template>
  <QuestionBase :question="question" :errors="[...jsErrors]" :valid="!!data.length" :id="db.saveField">
    <div class="question-box with-arrow mt-32">
      <transition name="fade">
        <div class="accordion sub-items">

          <ObjectCard
              v-for="(address, index) in data"
              v-model="data[index]"
              :ref="'address'+index"
              :id="'address'+index"
              :key="'address' + address.id"
              :title="`${ address.address.line_1}`"
              type="address"
              :loading="loading"
              @delete="deleteObject(address.id)"
              @save="saveObject(null, 'address'+index)"
          >
            <AddressFull v-model="data[index].address" :objectId="address.address.id"
                         :key="'address-form' + address.id"/>
            <AddressPrevious v-model="data[index]" :objectId="address.id" :key="'previous-address-form' + address.id"/>

          </ObjectCard>

          <ObjectCard
              v-if="form"
              v-model="form"
              type="address-add"
              :loading="loading"
              title="New Address"
              @delete="form=null"
              @save="saveObject(form)"
          >
            <div class="question-text">
              What was your previous Address?
            </div>
            <AddressSearch
                ref="addressSearch"
                :mode-data="mode"
                :data="null"
                :formData="form ? form.address : null"
                :disable-manual-mode="true"
                label="Search"
                @modeUpdate="mode=$event"
                @formUpdate="form.address=$event"
            />

            <hr>

            <AddressFull v-model="form.address" :noSave="true" :errors-post="errorsPost.address"/>

            <AddressPrevious v-model="form" :noSave="true" :errors-post="errorsPost"/>

          </ObjectCard>

          <b-button
              v-if="!form"
              @click="addAddress"
              class="btn-question w-100"
          >
            <i class="i-Home1 text-25 font-weight-800 mr-2">
            </i>
            Add Address
          </b-button>
        </div>
      </transition>
    </div>
  </QuestionBase>

</template>

<script>
import {clone} from "@/mixins/clone";
import {objectSaveHelpers} from "../../objects/objectSaveHelpers";
import {questionLoadHelpers} from "../../../helpers/questionLoadHelpers";
import {blankForms} from "../../../helpers/blankForms";

import QuestionBase from "../../../QuestionBase";
import ObjectCard from "../../objects/ObjectCard";
import AddressFull from "../../../../fieldsets/sub/address/AddressFull";
import AddressPrevious from "../../../../fieldsets/sub/address/AddressPrevious";
import AddressSearch from "../../../../fieldsets/sub/address/AddressSearch";

export default {
  name: 'AddressHistory',
  components: {AddressSearch, AddressPrevious, AddressFull, ObjectCard, QuestionBase},
  mixins: [objectSaveHelpers, questionLoadHelpers, clone, blankForms],
  props: {
    value: {
      type: Array,
      required: false
    },
    yearsRequired: {
      type: Number,
      default: 3
    }
  },
  data() {
    return {
      question: {
        title: 'What is your address history?',
        subTitle: `Please add your address history for the last ${this.yearsRequired} years`,
        tip: null,
      },
      db: {
        saveLocation: 'client_previous_address',
        saveField: 'previous_address',
        deleteLocation: 'client_previous_address',
        loadLocation: 'client_previous_address',
        syncMultipleObjectOnSave: false,
        fetchObjectsOnSave: true,
      },
      args: {
        skippable: false,
        required: true,
        customErrorMessageNewOpenForm: "Save address before proceeding, by clicking 'confirm'"
      },
      mode: {
        search: false,
        manual: false
      },
      serverErrors: [],
      errorsPost: {},
      jsErrors: [],
      skipped: false,
      form: null,
      errors: {}
    }
  },
  computed: {
    data: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    }
  },
  methods: {
    addAddress() {
      this.form = null
      this.form = this.clone(this.addressHistoryBlankForm)
    }
  }
}
</script>
