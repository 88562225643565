<template>
  <QuestionBase :disabled-valid="true"
                :errors="[...errorsPost, ...serverErrors, ...jsErrors]"
                :question="question"
                :valid="!!data">
    <InputBasic v-model="data"
                :args="args"
                :db="db"
                :noSave="noSave"
                :skipped="skipped"
                @jsErrors="jsErrors=$event"
                @serverErrors="serverErrors=$event"
                @skipped="skipped=$event" />
  </QuestionBase>
</template>

<script>
import {questionLoadHelpers} from "../../helpers/questionLoadHelpers";

import QuestionBase from "../../QuestionBase";
import InputBasic from "../../inputs/InputBasic";

export default {
  name: 'SumAssured',
  mixins: [questionLoadHelpers],
  components: {
    InputBasic,
    QuestionBase
  },
  props: {
    value: {
      type: [
        Number,
        String
      ],
      required: false
    },
    noSave: {
      type: Boolean,
      default: false
    },
    policyType: {
      type: String,
    },
    errorsPost: {
      type: Array,
      default: () => {
        return []
      }
    },
    objectId: {
      type: Number,
      required: false
    },
  },
  computed: {
    data: {
      set(value) {
        this.$emit('input',
            value)
      },
      get() {
        return this.value
      }
    },
    question() {
      return {
        title: `${this.policyType === 'Income Protection' ? 'How much will the policy pay out on a monthly basis?' : 'How much will the policy pay out?'}`,
        subTitle: 'If you are not certain please provide an estimate.',
        tip: null,
      }
    },
  },
  data() {
    return {
      db: {
        saveLocation: 'client_policy',
        saveField: 'policySumAssured',
        objectId: this.objectId
      },
      args: {
        placeholder: 'e.g. 300000',
        label: null,
        type: 'number',
        skippable: false,
        required: false,
        inputClass: 'field-66',
        appendText: this.policyType === 'Income Protection' ? 'Per Month' : null,
        prependText: '£'
      },
      serverErrors: [],
      jsErrors: [],
      skipped: false,
    }
  }
}
</script>