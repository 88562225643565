<template>
  <div>

    <AmountCurrent
        v-model="form.incomeCommonAmountCurrent"
        :errors-post="errorsPost.incomeCommonAmountCurrent"
        :object-id="objectId"
        :no-save="noSave"
        title="How much is the income?"
    />

    <AmountFrequency
        v-model="form.incomeCommonAmountFrequency"
        :errors-post="errorsPost.incomeCommonAmountFrequency"
        :object-id="objectId"
        :no-save="noSave"
    />

    <BenefitsType
        v-model="form.incomeBenefitsType"
        :errors-post="errorsPost.incomeBenefitsType"
        :object-id="objectId"
        :no-save="noSave"
    />


  </div>
</template>

<script>
import AmountCurrent from "@/components/common/questionnaires/question/questions/clientIncome/AmountCurrent";
import AmountFrequency from "@/components/common/questionnaires/question/questions/clientIncome/AmountFrequency";
import BenefitsType from "@/components/common/questionnaires/question/questions/clientIncome/BenefitsType";

export default {
  name: 'Benefits',
  components: {
    BenefitsType,
    AmountFrequency,
    AmountCurrent,


  },
  props: {
    value: {
      type: Object,
      required: true
    },
    objectId: {
      type: Number,
      required: false
    },
    noSave: {
      type: Boolean,
      default: false
    },
    errorsPost: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
    }
  },
  computed: {
    form: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    }
  }
}

</script>
