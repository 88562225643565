<template>
  <QuestionBase :question="question" :errors="[...serverErrors, ...jsErrors]" :valid="data !== null">
    <InputSelect
        v-model="data"
        :options="nameChangeMethodOptions"
        :db="db"
        :args="args"
        :skipped="skipped"
        :js-errors="jsErrors"
        @skipped="skipped=$event"
        @serverErrors="serverErrors=$event"
        @jsErrors="jsErrors=$event"
    />
  </QuestionBase>
</template>

<script>
import { questionLoadHelpers } from "../../../helpers/questionLoadHelpers";
import { statusOptions } from "../../../options/statusOptions";

import QuestionBase from "../../../QuestionBase";
import InputSelect from "../../../inputs/InputSelect";

export default {
  name: 'PreviousNameMethod',
  mixins: [statusOptions, questionLoadHelpers],
  components: {
    InputSelect,
    QuestionBase
  },
  props: {
    value: {
      type: String,
      required: false
    },
    saveLocation: {
      type: String,
      default: 'client_client'
    }
  },
  computed: {
    data: {
      set(value) {
        this.$emit('input', value)
      },
      get () {
        return this.value
      }
    }
  },
  data () {
    return {
      question: {
        title:  'How did you change your name?',
        subTitle:  null,
        tip: null,
      },
      db: {
        saveLocation: this.saveLocation,
        saveField: 'profilePreviousNameChangedMethod'
      },
      args: {
        taggable: false,
        label: null,
        placeholder: 'Please select..',
        skippable: true,
        required: true,
        inputClass: 'field-66'
      },
      serverErrors: [],
      jsErrors: [],
      skipped: false,
    }
  }
}
</script>
