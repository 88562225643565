<template>
  <div class="fieldset">
    <h3 class="fieldset-heading">Commitments</h3>
      <Commitments v-model="form.commitments"  @refresh="refreshCommitments" ref="commitments" />
  </div>
</template>

<script>
import {fieldsetHelpers} from "../fieldsetHelpers";

import Commitments from "../../question/questions/clientProfile/commitments/Commitments";

export default {
  name: 'CommitmentsFull',
  mixins: [fieldsetHelpers],
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  components: {
    Commitments,
  },
  data() {
    return {
      label: 'Commitments', // used for steps progress
      videoUrl: 'https://dm-kernel-public.s3.eu-west-2.amazonaws.com/static/video/kernel_help/mortgage_liabilities.mp4',
    }
  },
  computed: {
    form: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    }
  },
  methods: {
    refreshCommitments () {
      if ('commitments' in this.$refs) this.$refs.commitments.get()
    }
  }
};
</script>
