<template>
  <div class="fieldset">
    <h3 class="fieldset-heading">Relationship Status</h3>

    <RelationshipCurrentlyYN
        v-model="form.profileRelationshipCurrentlyYN"
        :status-function="calculateRelationshipStatus"
    />

    <template v-if="form.profileRelationshipCurrentlyYN">
      <div class="question-box with-arrow">
        <RelationshipStatus
            v-model="form.profileRelationshipCurrentStatus"
            :status-function="calculateRelationshipStatus"
        />
        <template v-if="showRelationshipDuration">
          <Partner v-model="form.partner"/>
          <RelationshipDuration v-model="form.profileRelationshipDuration"/>
        </template>
      </div>
    </template>


    <template v-if="form.profileRelationshipCurrentlyYN === false">

      <RelationshipPreviousYN
          v-model="form.profileRelationshipPreviousYN"
          :status-function="calculateRelationshipStatus"
      />
      <template v-if="form.profileRelationshipPreviousYN">
        <div class="question-box with-arrow">
          <RelationshipPreviousStatus
              v-model="form.profileRelationshipPreviousStatus"
              save-field="profileRelationshipStatus"/>
        </div>
      </template>

    </template>


  </div>
</template>

<script>
import {fieldsetHelpers} from "../fieldsetHelpers";
import {statusOptions} from "../../question/options/statusOptions";
import {
  relationshipStatusHelpers
} from "@/components/common/questionnaires/question/questions/clientProfile/relationship/relationshipStatusHelpers";

import RelationshipStatus from "../../question/questions/clientProfile/relationship/RelationshipStatus";
import RelationshipDuration from "../../question/questions/clientProfile/relationship/RelationshipDuration";

import RelationshipCurrentlyYN
  from "@/components/common/questionnaires/question/questions/clientProfile/relationship/RelationshipCurrentlyYN";
import RelationshipPreviousYN
  from "@/components/common/questionnaires/question/questions/clientProfile/relationship/RelationshipPreviousYN";
import RelationshipPreviousStatus
  from "@/components/common/questionnaires/question/questions/clientProfile/relationship/RelationshipPreviousStatus";
import Partner from "@/components/common/questionnaires/question/questions/clientProfile/status/Partner";

export default {
  name: 'RelationshipMortgage',
  mixins: [fieldsetHelpers, statusOptions, relationshipStatusHelpers],
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  components: {
    RelationshipPreviousStatus,
    RelationshipPreviousYN,
    RelationshipCurrentlyYN,
    Partner,
    RelationshipDuration,
    RelationshipStatus,
  },
  data() {
    return {
      label: 'Relationship Status', // used for steps progress
      videoUrl: 'https://dm-kernel-public.s3.eu-west-2.amazonaws.com/static/video/kernel_help/marital_status.mp4'

    }
  },
  computed: {
    form: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    },
    showRelationshipDuration() {
      return this.partnerListOptions.includes(this.form.profileRelationshipStatus)
    }
  }
};
</script>
