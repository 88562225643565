<template>
  <div>
    <Lender
        v-model="form.liabilityLender"
        :errors-post="errorsPost.liabilityLender"
        :object-id="objectId"
        :no-save="noSave"
    />

    <Balance
        v-model="form.liabilityBalance"
        :errors-post="errorsPost.liabilityBalance"
        :object-id="objectId"
        :no-save="noSave"
    />

    <Payment
        v-model="form.liabilityPayment"
        :errors-post="errorsPost.liabilityPayment"
        :object-id="objectId"
        :no-save="noSave"
    />

    <InterestRate
        v-model="form.liabilityInterestRate"
        :errors-post="errorsPost.liabilityInterestRate"
        :object-id="objectId"
        :no-save="noSave"
    />

    <InterestRateType
        v-model="form.liabilityInterestRateType"
        :errors-post="errorsPost.liabilityInterestRateType"
        :object-id="objectId"
        :no-save="noSave"
    />

    <RepaymentType
        v-model="form.liabilityRepaymentType"
        :errors-post="errorsPost.liabilityRepaymentType"
        :object-id="objectId"
        :no-save="noSave"
    />

    <ProductEndDateMortgage
        v-model="form.liabilityProductEndDate"
        :errors-post="errorsPost.liabilityProductEndDate"
        :object-id="objectId"
        :no-save="noSave"
    />

    <RemainingTerm
        :value-remaining-term-years="form.liabilityRemainingTerm"
        :value-remaining-term-months="form.liabilityRemainingTermMonths"
        :errors-post-years="errorsPost.liabilityRemainingTerm"
        :errors-post-months="errorsPost.liabilityRemainingTermMonths"
        :object-id="objectId"
        :no-save="noSave"
        @inputRemainingTermYears="form.liabilityRemainingTerm=$event"
        @inputRemainingTermMonths="form.liabilityRemainingTermMonths=$event"
    />


    <Responsibility
        v-model="form.clients"
        :errors-post="errorsPost.clients"
        :object-id="objectId"
        :no-save="noSave"
        :other="form.owner_other"
        @other="form.owner_other=$event"
    />

    <ResponsibilityOtherDetails
        v-if="form.owner_other"
        v-model="form.owner_other_details"
        :errors-post="errorsPost.owner_other_details"
        :object-id="objectId"
        :no-save="noSave"
    />
  </div>
</template>

<script>
import Responsibility from "../../../../question/questions/clientLiability/Responsibility";
import ResponsibilityOtherDetails from "../../../../question/questions/clientLiability/ResponsibilityOtherDetails";
import Balance from "../../../../question/questions/clientLiability/Balance";
import Payment from "../../../../question/questions/clientLiability/Payment";
import Lender from "../../../../question/questions/clientLiability/Lender";
import RepaymentType from "../../../../question/questions/clientLiability/RepaymentType";
import RemainingTerm from "../../../../question/questions/clientLiability/RemainingTerm";

import InterestRate from "../../../../question/questions/clientLiability/InterestRate";
import InterestRateType from "../../../../question/questions/clientLiability/InterestRateType";
import ProductEndDateMortgage from "../../../../question/questions/clientLiability/ProductEndDateMortgage";

export default {
  name: 'LiabilityMortgageMortgage',
  components: {
    RemainingTerm,
    RepaymentType,
    Lender,
    Payment,
    Balance,
    ResponsibilityOtherDetails,
    Responsibility,
    InterestRate,
    InterestRateType,
    ProductEndDateMortgage,
  },
  props: {
    value: {
      type: Object,
      required: true
    },
    objectId: {
      type: Number,
      required: false
    },
    noSave: {
      type: Boolean,
      default: false
    },
    errorsPost: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  computed: {
    form: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    }
  }
}
</script>
