export const assetOptions = {
    data() {
        return {
            assetTypeOptions: [
                {
                    label: "Residential Home",
                    value: "Residential Home"
                },
                {
                    label: "Second Home",
                    value: "Second Home"
                },
                {
                    label: "Household Contents",
                    value: "Household Contents"
                },
                {
                    label: "Investment Property",
                    value: "Investment Property"
                },
                {
                    label: "Savings",
                    value: "Savings"
                },
                {
                    label: "Bank Accounts",
                    value: "Bank Accounts"
                },
                {
                    label: "Vehicles",
                    value: "Vehicles"
                },
                {
                    label: "Pensions",
                    value: "Pensions"
                },
                {
                    label: "Shares",
                    value: "Shares"
                },
                {
                    label: "Bonds",
                    value: "Bonds"
                },
                {
                    label: "Jewellery",
                    value: "Jewellery"
                }
            ],
            assetOwnershipOptions: [
                {
                    label: "Sole",
                    value: "Sole",
                },
                {
                    label: "Joint (With Partner)",
                    value: "Joint (With Partner)",
                },
                {
                    label: "Joint (With Another)",
                    value: "Joint (With Another)",
                },
                {
                    label: 'Other',
                    value: 'Other'
                }
            ],
            assetsMainOptions: [
                {
                    label: "Residential Home",
                    value: "Residential Home"
                },
                {
                    label: "Household Contents",
                    value: "Household Contents"
                },
                {
                    label: "Vehicles",
                    value: "Vehicles"
                },
                {
                    label: "Savings",
                    value: "Savings"
                },
                {
                    label: "Shares",
                    value: "Shares"
                },
                {
                    label: "Pensions",
                    value: "Pensions"
                },
            ],
            assetsMainMortgageOptions: [
                {
                    label: "Residential Home",
                    value: "Residential Home"
                },
                {
                    label: "Second Home",
                    value: "Second Home"
                },
                {
                    label: "Investment Property",
                    value: "Investment Property"
                },
                {
                    label: "Savings",
                    value: "Savings"
                },
                {
                    label: "Shares",
                    value: "Shares"
                },
                {
                    label: "Pensions",
                    value: "Pensions"
                },
            ],
            giftsMainOptions: [
                {
                    label: 'Money',
                    value: 'cash'
                },
                {
                    label: 'Property',
                    value: 'property'
                },
                {
                    label: 'Jewellery',
                    value: 'jewellery'
                },
                {
                    label: 'Account',
                    value: 'account'
                },
                {
                    label: 'Vehicle',
                    value: 'vehicle'
                },
                {
                    label: 'Other',
                    value: 'other'
                }
            ],
            assetTenureOptions: [
                {
                    label: 'Joint Tenancy',
                    value: 'Joint Tenancy'
                },
                {
                    label: 'Tenants in Common',
                    value: 'Tenants in Common'
                }
            ],
            providerRequiredAssets: [
                'Savings',
                'Bank Accounts',
                'Pensions',
                'Shares',
                'Bonds'
            ]

        }
    }
}