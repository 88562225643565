export const liabilityOptions = {
    data () {
        return {
            liabilityTypeOptions: [
                {
                    label: 'Mortgage',
                    value: 'Mortgage'
                },
                {
                    label: 'Mortgage (BTL)',
                    value: 'Mortgage (BTL)'
                },
                {
                    label: 'Loan',
                    value: 'Loan'
                },
                {
                    label: 'Car PCP',
                    value: 'Car PCP'
                },
                {
                    label: 'Hire Purchase Agreement',
                    value: 'Hire Purchase Agreement'
                },
                {
                    label: 'Credit Card',
                    value: 'Credit Card'
                },
                {
                    label: 'Store Card',
                    value: 'Store Card'
                },
                {
                    label: 'Student Loan',
                    value: 'Student Loan'
                },
                {
                    label: 'Family Loan',
                    value: 'Family Loan'
                },
                {
                    label: 'Other',
                    value: 'Other'
                }
            ],
            liabilityInterestRateOptions: [
                {
                    label: 'Fixed',
                    value: 'Fixed'
                },
                {
                    label: 'Variable',
                    value: 'Variable'
                },
            ],
            liabilityRepaymentTypeOptions: [
                {
                    label: "Repayment",
                    value: "Repayment"
                },
                {
                    label: "Interest Only",
                    value: "Interest Only"
                },
                {
                    label: "Part and Part",
                    value: "Part Repayment / Part Interest Only"
                }
            ]
        }
    }
}