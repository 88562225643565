<template>
  <QuestionBase :id="db.saveField"
                :errors="[...jsErrors]"
                :question="question"

                :valid="!!data.length">
    <transition name="fade">
      <div class="accordion sub-items">

        <ObjectCard v-for="(liability, index) in data"
                    :id="'liability'+index"
                    :key="'liability' + liability.id"
                    :ref="'liability'+index"
                    v-model="data[index]"
                    :title="`${liability.liabilityType} ${liability.liabilityLender ? liability.liabilityLender : ''}`"
                    type="liability"
                    :loading="loading"
                    @delete="deleteObject(liability.id)"
                    @save="saveObject(liability, 'liability'+index)">
          <Component :is="formType"
                     :key="'liability-form' + liability.id"
                     v-model="data[index]"
                     :objectId="liability.id" />
        </ObjectCard>

        <ObjectCard v-if="form"
                    ref="liability-add"
                    v-model="form"
                    title="Mortgage"
                    type="liability-add"
                    :loading="loading"
                    @delete="form=null"
                    @save="saveObject(form, db.saveField)">
          <Component :is="formType"
                     v-model="form"
                     :errors-post="errorsPost"
                     :noSave="true" />
        </ObjectCard>
        <b-button v-if="!form"
                  class="btn-question w-100"
                  @click="add">
          <i class="i-Home-2 text-25 font-weight-800 mr-2"> </i> Add Mortgage
        </b-button>
      </div>
    </transition>
  </QuestionBase>
</template>
<script>
import {objectSaveHelpers} from "../../objects/objectSaveHelpers";
import {questionLoadHelpers} from "../../../helpers/questionLoadHelpers";
import {blankForms} from "../../../helpers/blankForms";

import QuestionBase from "../../../QuestionBase";
import ObjectCard from "../../objects/ObjectCard";
import LiabilityMortgageFull from "../../../../fieldsets/sub/liability/LiabilityMortgageFull";
import LiabilityMortgageMortgage from "../../../../fieldsets/sub/liability/mortgage/LiabilityMortgageMortgage";
import LiabilityMortgageEstatePlanning from "../../../../fieldsets/sub/liability/estatePlanning/LiabilityMortgageEstatePlanning";

export default {
  name: 'LiabilitiesMortgages',
  components: {
    LiabilityMortgageFull,
    ObjectCard,
    QuestionBase,
    LiabilityMortgageEstatePlanning,
    LiabilityMortgageMortgage
  },
  mixins: [
    objectSaveHelpers,
    questionLoadHelpers,
    blankForms
  ],
  props: {
    value: {
      type: Array,
      required: false
    },
    type: {
      type: String,
      default: 'full'
    }
  },
  data() {
    return {
      question: {
        title: null,
        subTitle: null,
        tip: null,
      },
      db: {
        saveLocation: 'client_liability',
        saveField: 'liabilities_mortgages',
        deleteLocation: 'client_liability',
        loadLocation: 'client_liabilities_mortgages',
        syncMultipleObjectOnSave: true,
        fetchObjectsOnSave: false
      },
      args: {
        skippable: false,
        required: true,
        customErrorMessageNewOpenForm: "Save mortgage before proceeding, by clicking 'confirm'"
      },
      serverErrors: [],
      errorsPost: {},
      jsErrors: [],
      skipped: false,
      form: null,
      errors: {}
    }
  },
  computed: {
    data: {
      set(value) {
        this.$emit('input',
            value)
      },
      get() {
        return this.value
      }
    },
    formType() {
      if (this.type === 'mortgage') return 'LiabilityMortgageMortgage'
      if (this.type === 'estatePlanning') return 'LiabilityMortgageEstatePlanning'
      return 'LiabilityMortgageFull'
    }
  },
  methods: {
    add() {
      this.form = null
      this.form = Object.assign({}, this.liabilityBlankForm)
      this.form.liabilityType = 'Mortgage'
    }
  }
}
</script>
