<template>
  <div>
    <Type
        v-if="showType"
        v-model="form.policyType"
        :errors-post="errorsPost.policyType"
        :object-id="objectId"
        :no-save="noSave"
    />
    <SumAssured
        v-model="form.policySumAssured"
        :policyType="form.policyType"
        :errors-post="errorsPost.policySumAssured"
        :object-id="objectId"
        :no-save="noSave"
    />
    <MortgageRelated
        v-model="form.policyMortgageRelated"
        :errors-post="errorsPost.policyMortgageRelated"
        :object-id="objectId"
        :no-save="noSave"
    />
    <Owners
        v-model="form.clients"
        :errors-post="errorsPost.clients"
        :object-id="objectId"
        :no-save="noSave"
        :other="form.owner_other"
        @other="form.owner_other=$event"
    />
    <OwnerOtherDetails
        v-if="form.owner_other"
        v-model="form.owner_other_details"
        :errors-post="errorsPost.owner_other_details"
        :object-id="objectId"
        :no-save="noSave"
    />
    <Term
        v-if="form.type !== 'Whole of Life'"
        v-model="form.policyTerm"
        :errors-post="errorsPost.policyTerm"
        :object-id="objectId"
        :no-save="noSave"
    />
  </div>
</template>

<script>
import SumAssured from "../../../question/questions/clientPolicy/SumAssured";
import OwnerOtherDetails from "../../../question/questions/clientPolicy/OwnerOtherDetails";
import Owners from "../../../question/questions/clientPolicy/Owners";
import MortgageRelated from "../../../question/questions/clientPolicy/MortgageRelated";
import Term from "../../../question/questions/clientPolicy/Term";
import Type from "../../../question/questions/clientPolicy/Type";


export default {
  name: 'PolicyMortgage',
  components: {
    Type,
    Owners,
    OwnerOtherDetails,
    SumAssured,
    MortgageRelated,
    Term
  },
  props: {
    value: {
      type: Object,
      required: true
    },
    objectId: {
      type: Number,
      required: false
    },
    noSave: {
      type: Boolean,
      default: false
    },
    errorsPost: {
      type: Object,
      default: () => {
        return {}
      }
    },
    showType: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    form: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    }
  }
}
</script>