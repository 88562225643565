<template>
  <QuestionBase
      :question="question"
      :valid="!!(valueRemainingTermYears && valueRemainingTermMonths)"
      :errors="errors"
  >
  <b-row>
    <b-col>
        <InputBasic
            :value="valueRemainingTermYears"
            @input="$emit('inputRemainingTermYears', $event)"
            ref="liabilityRemainingTerm"
            :args="yearInputOptions"
            :db="db.years"
            :skipped="skipped"
            :no-save="noSave"
            :errors-post="errorsPostYears"
            @skipped="skipped=$event"
            @serverErrors="serverErrors.liabilityRemainingTerm=$event"
            @jsErrors="jsErrors=$event"

        />
    </b-col>
    <b-col>
        <InputBasic
            :value="valueRemainingTermMonths"
            @input="$emit('inputRemainingTermMonths', $event)"
            ref="liabilityRemainingTermMonths"
            :args="monthInputOptions"
            :db="db.months"
            :skipped="skipped"
            :serverErrors="serverErrors"
            :no-save="noSave"
            :errors-post="errorsPostMonths"
            @skipped="skipped=$event"
            @serverErrors="serverErrors.liabilityRemainingTermMonths=$event"
            @jsErrors="jsErrors=$event"
        />
    </b-col>
  </b-row>


    <div class="question-multipart-wrapper d-flex flex-wrap align-items-end">
      <div class="question question-multipart field-50">
      </div>
      <div class="question question-multipart field-50">

      </div>
    </div>
  </QuestionBase>
</template>

<script>
import {skipHelpers} from "../helpers/skipHelpers";
import {questionLoadHelpers} from "../../helpers/questionLoadHelpers";

import QuestionBase from "../../QuestionBase";
import InputBasic from "../../inputs/InputBasic";

export default {
  name: 'RemainingTerm',
  components: {
    InputBasic,
    QuestionBase
  },
  mixins: [skipHelpers, questionLoadHelpers],
  props: {
    valueRemainingTermYears: {
      type: [Number,String],
      required: false
    },
    valueRemainingTermMonths: {
      type: [Number,String],
      required: false
    },
    noSave: {
      type: Boolean,
      default: false
    },
    errorsPostYears: {
      type: Array,
      default: () => {
        return []
      }
    },
    errorsPostMonths: {
      type: Array,
      default: () => {
        return []
      }
    },
    objectId: {
      type: Number,
      required: false
    },
  },
  computed: {
    errors() {
      return [...this.errorsPostYears, ...this.errorsPostMonths,  ...this.serverErrors.liabilityRemainingTerm, ...this.serverErrors.liabilityRemainingTermMonths, ...this.jsErrors]
    }
  },
  data() {
    return {
      question: {
        title: 'In how many years and months from now will this liability be repaid in full?',
        subTitle: null,
        tip: null
      },
      yearInputOptions: {
        placeholder: 'e.g. 12',
        label: null,
        type: 'number',
        skippable: false,
        required: false,
        appendText: 'Years'
      },
      monthInputOptions: {
        placeholder: 'e.g. 6',
        label: null,
        type: 'number',
        skippable: false,
        required: false,
        appendText: 'Months'
      },
      db: {
        saveLocation: 'client_liability',
        saveField: 'liabilityRemainingTerm',
        years: {
          saveLocation: 'client_liability',
          saveField: 'liabilityRemainingTerm',
          objectId: this.objectId
        },
        months: {
          saveLocation: 'client_liability',
          saveField: 'liabilityRemainingTermMonths',
          objectId: this.objectId
        },
      },
      args: {
        skippable: false,
        required: true,
      },
      serverErrors: {
        liabilityRemainingTerm: [],
        liabilityRemainingTermMonths: [],
      },
      jsErrors: [],
      skipped: false,
    }
  }
}
</script>
