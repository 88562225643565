<template>
  <QuestionBase :errors="[...errorsPost, ...serverErrors, ...jsErrors]"
                :question="question"
                :valid="!!data">
    <InputBasic v-model="data"
                :args="args"
                :db="db"
                :noSave="noSave"
                :skipped="skipped"
                @jsErrors="jsErrors=$event"
                @serverErrors="serverErrors=$event"
                @skipped="skipped=$event" />
  </QuestionBase>
</template>

<script>
import {questionLoadHelpers} from "../../helpers/questionLoadHelpers";

import QuestionBase from "../../QuestionBase";
import InputBasic from "../../inputs/InputBasic";

export default {
  name: 'Value',
  mixins: [questionLoadHelpers],
  components: {
    InputBasic,
    QuestionBase
  },
  props: {
    value: {
      type: [
        Number,
        String
      ],
      required: false
    },
    noSave: {
      type: Boolean,
      default: false
    },
    errorsPost: {
      type: Array,
      default: () => {
        return []
      }
    },
    objectId: {
      type: Number,
      required: false
    },
  },
  computed: {
    data: {
      set(value) {
        this.$emit('input',
            value)
      },
      get() {
        return this.value
      }
    }
  },
  data() {
    return {
      question: {
        title: 'What is the approximate value of the asset?',
        subTitle: 'If you are not certain of the value please just add an estimate.',
        tip: null
      },
      db: {
        saveLocation: 'client_asset',
        saveField: 'assetValue',
        objectId: this.objectId
      },
      args: {
        placeholder: 'e.g. 6000',
        label: null,
        type: 'number',
        skippable: false,
        required: false,
        inputClass: 'field-66',
        prependText: '£'
      },
      serverErrors: [],
      jsErrors: [],
      skipped: false,
    }
  }
}
</script>