<template>
  <QuestionBase :id="db.saveField"
                :errors="[...jsErrors]"
                :question="question"
                class="pb-0"
                :valid="!!data.length">
    <transition name="fade">
      <div class="accordion sub-items">
        <div class="question-box with-arrow mt-32 pb-30">

          <ObjectCard v-for="(commitment, index) in data"
                      :id="'commitment'+index"
                      :key="'commitment' + commitment.id"
                      :ref="'commitment'+index"
                      v-model="data[index]"
                      :title="`£${commitment.commitmentAmount} ${commitment.commitmentType} ${responsibles(commitment)}`"
                      type="commitment"
                      :loading="loading"
                      @delete="deleteObject(commitment.id)"
                      @save="saveObject(commitment, 'commitment'+index)">
            <CommitmentFull :key="'commitment-form' + commitment.id"
                                v-model="data[index]"
                                :objectId="commitment.id" />
          </ObjectCard>

          <ObjectCard v-if="form"
                      ref="commitment-add"
                      v-model="form"
                      title="New Commitment"
                      type="commitment-add"
                      :loading="loading"
                      @delete="form=null"
                      @save="saveObject(form, db.saveField)">
            <CommitmentFull v-model="form"
                                :errors-post="errorsPost"
                                :noSave="true" />
          </ObjectCard>

          <ObjectCard v-if="form_multi"
                      ref="commitment-add-multi"
                      v-model="form_multi"
                      title="Commitments"
                      type="commitment-add"
                      :loading="loading"
                      @delete="form_multi=null"
                      @save="saveObjectMulti(form_multi)">

            <CommitmentShort v-for="(formItem, index) in multi_form_options" :key="index" v-model="form_multi[formItem]"
                                :errors-post="errorsPost"
                                :noSave="true" />

          </ObjectCard>

          <b-button v-if="data.length"
                    :class="data.length ? 'w-50' : null"
                    class="btn-question"
                    @click="add">
            <i class="i-Money-Bag text-25 font-weight-800 mr-2"> </i> Add Single
          </b-button>

          <b-button class="btn-question"
                    :class="data.length ? 'w-50' : null"
                    @click="addMulti">
            <i class="i-Money-Bag text-25"> </i><i class="i-Money-Bag text-25 font-weight-800 mr-2"> </i> Add Multiple
          </b-button>
          </div>
      </div>
    </transition>
  </QuestionBase>
</template>

<script>
import {objectSaveHelpers} from "../../objects/objectSaveHelpers";
import {questionLoadHelpers} from "../../../helpers/questionLoadHelpers";
import {blankForms} from "../../../helpers/blankForms";
import { clientHelpers } from "@/mixins/clientHelpers";

import QuestionBase from "../../../QuestionBase";
import ObjectCard from "../../objects/ObjectCard";
import CommitmentFull from "../../../../fieldsets/sub/commitment/CommitmentFull";
import CommitmentShort from "../../../../fieldsets/sub/commitment/CommitmentShort";

export default {
  name: 'Commitments',
  components: {
    CommitmentFull,
    CommitmentShort,
    ObjectCard,
    QuestionBase,
  },
  mixins: [
    objectSaveHelpers,
    questionLoadHelpers,
    blankForms,
    clientHelpers
  ],
  props: {
    value: {
      type: Array,
      required: false
    }
  },
  data() {
    return {
      question: {
        title: 'Please add any details of any monthly commitments (excluding credit commitments)',
        subTitle: 'There is no need to add details of loans, mortgages and other credit commitments. ' +
            'Similarly insurance premiums need not be added.',
        tip: null,
      },
      db: {
        saveLocation: 'client_commitment',
        saveField: 'commitments',
        deleteLocation: 'client_commitment',
        loadLocation: 'client_commitment',
        syncMultipleObjectOnSave: true,
        fetchObjectsOnSave: false
      },
      args: {
        skippable: false,
        required: true,
        customErrorMessageNewOpenForm: "Save commitment before proceeding, by clicking 'confirm'"
      },
      serverErrors: [],
      errorsPost: {},
      jsErrors: [],
      skipped: false,
      form: null,
      form_multi: null,
      errors: {}
    }
  },
  computed: {
    multi_form_options(){
      if(this.form_multi){
        return Object.keys(this.form_multi);
      }
      return null;
    },
    data: {
      set(value) {
        this.$emit('input',
            value)
      },
      get() {
        return this.value
      }
    },
    family_clients () {
      if (this.$store.getters.family) return this.$store.getters.family.clients
      return []
    },
  },
  methods: {
    responsibles(commitment){
      let value = '<br><small>';
      let clientNames = [];
      if(commitment.clients && commitment.clients.length){
        clientNames = commitment.clients.map(x=> { return this.clientNameFirstLast(this.family_clients.find(c=> c.id===x)) } )
      }
      if(commitment.owner_other_details){
        clientNames.push(commitment.owner_other_details)
      }
      value += clientNames.join(', ');
      value +='</small>';
      return value;
    },
    saveObjectMulti(form_multi){
      Object.keys(form_multi).forEach(x=>{
        this.saveObject(form_multi[x],this.db.saveLocation)
      })
      this.form=null;
      this.form_multi=null;
    },
    add() {
      this.form = null
      this.form = Object.assign({},
          this.commitmentBlankForm)
    },
    addMulti() {
      console.log('here');
      this.form_multi = null;
      this.form_multi = JSON.parse(JSON.stringify(this.commitmentBlankFormMulti));
    }
  }
}
</script>
