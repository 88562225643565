<template>
  <div>
    <SumAssured
        v-model="form.policySumAssured"
        :policyType="form.policyType"
        :errors-post="errorsPost.policySumAssured"
        :object-id="objectId"
        :no-save="noSave"
    />
    <MortgageRelated
        v-model="form.policyMortgageRelated"
        :errors-post="errorsPost.policyMortgageRelated"
        :object-id="objectId"
        :no-save="noSave"
    />

    <Owners
        v-model="form.clients"
        :errors-post="errorsPost.clients"
        :object-id="objectId"
        :no-save="noSave"
        :other="form.owner_other"
        @other="form.owner_other=$event"
    />
    <OwnerOtherDetails
        v-if="form.owner_other"
        v-model="form.owner_other_details"
        :errors-post="errorsPost.owner_other_details"
        :object-id="objectId"
        :no-save="noSave"
    />
    <Provider
        v-model="form.policyProvider"
        :errors-post="errorsPost.policyProvider"
        :object-id="objectId"
        :no-save="noSave"
    />

    <PremiumAmount
        v-model="form.policyPremiumAmount"
        :errors-post="errorsPost.policyPremiumAmount"
        :object-id="objectId"
        :no-save="noSave"
    />

    <StartDate
        v-model="form.policyStartDate"
        :errors-post="errorsPost.policyStartDate"
        :object-id="objectId"
        :no-save="noSave"
    />

    <Term
        v-if="form.type !== 'Whole of Life'"
        v-model="form.policyTerm"
        :errors-post="errorsPost.policyTerm"
        :object-id="objectId"
        :no-save="noSave"
    />

    <CoverType
        v-model="form.policyCoverType"
        :errors-post="errorsPost.policyCoverType"
        :object-id="objectId"
        :no-save="noSave"
    />

    <PremiumType
        v-model="form.policyPremiumType"
        :errors-post="errorsPost.policyPremiumType"
        :object-id="objectId"
        :no-save="noSave"
    />

    <TrustStatus
        v-model="form.policyTrustStatus"
        :errors-post="errorsPost.policyTrustStatus"
        :object-id="objectId"
        :no-save="noSave"
    />

    <PolicyNumber
        v-model="form.policyNumber"
        :errors-post="errorsPost.policyNumber"
        :object-id="objectId"
        :no-save="noSave"
    />

    <Notes
        v-model="form.policyNotes"
        :errors-post="errorsPost.policyNotes"
        :object-id="objectId"
        :no-save="noSave"
    />

  </div>
</template>

<script>
import SumAssured from "../../../question/questions/clientPolicy/SumAssured";
import OwnerOtherDetails from "../../../question/questions/clientPolicy/OwnerOtherDetails";
import Notes from "../../../question/questions/clientPolicy/Notes";
import Owners from "../../../question/questions/clientPolicy/Owners";
import MortgageRelated from "../../../question/questions/clientPolicy/MortgageRelated";
import Provider from "../../../question/questions/clientPolicy/Provider";
import PremiumAmount from "../../../question/questions/clientPolicy/PremiumAmount";
import StartDate from "../../../question/questions/clientPolicy/StartDate";
import Term from "../../../question/questions/clientPolicy/Term";
import CoverType from "../../../question/questions/clientPolicy/CoverType";
import PremiumType from "../../../question/questions/clientPolicy/PremiumType";
import TrustStatus from "../../../question/questions/clientPolicy/TrustStatus";
import PolicyNumber from "../../../question/questions/clientPolicy/PolicyNumber";


export default {
  name: 'PolicyMainShort',
  components: {
    Owners, 
    Notes, 
    OwnerOtherDetails, 
    SumAssured, 
    MortgageRelated,
    Provider,
    PremiumAmount,
    StartDate,
    Term,
    CoverType,
    PremiumType,
    TrustStatus,
    PolicyNumber,
  },
  props: {
    value: {
      type: Object,
      required: true
    },
    objectId: {
      type: Number,
      required: false
    },
    noSave: {
      type: Boolean,
      default: false
    },
    errorsPost: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  computed: {
    form: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    }
  }
}
</script>