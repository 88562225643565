<template>
  <QuestionBase :question="question" :errors="[...jsErrors]" :valid="!!data.length" :id="db.saveField">
    <InputRadioMultiple
        v-model="assetsMainInput"
        @added="setForm"
        :db="db"
        :no-save="true"
        :options="options"
        class="radio-options-wide"
    />

    <div class="question-box with-arrow mt-32" v-if="data.length || forms.householdContents || forms.shares || forms.vehicles || forms.residentialHome || forms.pensions || forms.savings || forms.secondHome || forms.investmentProperty" >
    <transition name="fade">
      <div class="accordion sub-items">

        <ObjectCard
            v-for="(asset, index) in data"
            v-model="data[index]"
            :ref="'asset-main' + index"
            :id="'asset-main' + index"
            :key="'asset-main' + asset.id"
            :title="`${asset.assetType}`"
            type="asset"
            :loading="loading"
            @delete="deleteObject(asset.id, () => {removeFromMainInput(asset.assetType)})"
            @save="saveObject(null, 'asset-main' + index)"
        >
          <AssetMainFull v-model="data[index]" :objectId="asset.id" :key="'asset-form' + asset.id" />
        </ObjectCard>

        <ObjectCard
            v-if="forms.residentialHome"
            v-model="forms.residentialHome"
            ref="asset-add-residentialHome"
            type="asset-add"
            title="Enter Residential Home details"
            :loading="loading"
            @delete="removeForm('residentialHome')"
            @save="saveObject(forms.residentialHome, 'asset-add-residentialHome', null, 'residentialHome')"
        >
          <Component :is="formType" v-model="forms.residentialHome" :noSave="true" :errors-post="errorsPost.residentialHome" :showType="false" />
        </ObjectCard>

        <ObjectCard
            v-if="forms.secondHome"
            v-model="forms.secondHome"
            ref="asset-add-secondHome"
            type="asset-add"
            title="Enter Second Home details"
            :loading="loading"
            @delete="removeForm('secondHome')"
            @save="saveObject(forms.secondHome, 'asset-add-secondHome', null, 'secondHome')"
        >
          <Component :is="formType" v-model="forms.secondHome" :noSave="true" :errors-post="errorsPost.secondHome" :showType="false" />
        </ObjectCard>

        <ObjectCard
            v-if="forms.investmentProperty"
            v-model="forms.investmentProperty"
            ref="asset-add-investmentProperty"
            type="asset-add"
            title="Enter Investment Property details"
            :loading="loading"
            @delete="removeForm('investmentProperty')"
            @save="saveObject(forms.investmentProperty, 'asset-add-investmentProperty', null, 'investmentProperty')"
        >
          <Component :is="formType" v-model="forms.investmentProperty" :noSave="true" :errors-post="errorsPost.investmentProperty" :showType="false" />
        </ObjectCard>

        <ObjectCard
            v-if="forms.householdContents"
            v-model="forms.householdContents"
            ref="asset-add-householdContents"
            type="asset-add"
            title="Enter Household Contents details"
            :loading="loading"
            @delete="removeForm('householdContents')"
            @save="saveObject(forms.householdContents, 'asset-add-householdContents', null, 'householdContents')"
        >
          <Component :is="formType" v-model="forms.householdContents" :noSave="true" :errors-post="errorsPost.householdContents" :showType="false"/>
        </ObjectCard>
        <ObjectCard
            v-if="forms.vehicles"
            v-model="forms.vehicles"
            ref="asset-add-vehicles"
            type="asset-add"
            title="Enter details of Vehicles"
            :loading="loading"
            @delete="removeForm('vehicles')"
            @save="saveObject(forms.vehicles, 'asset-add-vehicles', null, 'vehicles')"
        >
          <Component :is="formType" v-model="forms.vehicles" :noSave="true" :errors-post="errorsPost.vehicles" :showType="false"/>
        </ObjectCard>

        <ObjectCard
            v-if="forms.savings"
            v-model="forms.savings"
            ref="asset-add-savings"
            type="asset-add"
            title="Enter details of Savings"
            :loading="loading"
            @delete="removeForm('savings')"
            @save="saveObject(forms.savings, 'asset-add-savings', null, 'savings')"
        >
          <Component :is="formType" v-model="forms.savings" :noSave="true" :errors-post="errorsPost.savings" :showType="false"/>
        </ObjectCard>

        <ObjectCard
            v-if="forms.shares"
            v-model="forms.shares"
            ref="asset-add-shares"
            type="asset-add"
            title="Enter details of Shares"
            :loading="loading"
            @delete="removeForm('shares')"
            @save="saveObject(forms.shares, 'asset-add-shares', null, 'shares')"
        >
          <Component :is="formType" v-model="forms.shares" :noSave="true" :errors-post="errorsPost.shares" :showType="false"/>
        </ObjectCard>

        <ObjectCard
            v-if="forms.pensions"
            v-model="forms.pensions"
            ref="asset-add-pensions"
            type="asset-add"
            title="Enter details of Pensions"
            :loading="loading"
            @delete="removeForm('pensions')"
            @save="saveObject(forms.pensions, 'asset-add-pensions', null, 'pensions')"
        >
          <Component :is="formType" v-model="forms.pensions" :noSave="true" :errors-post="errorsPost.pensions" :showType="false"/>
        </ObjectCard>

      </div>
    </transition>
    </div>
  </QuestionBase>

</template>

<script>
import { assetOptions } from "../../../options/assetOptions";
import { objectSaveHelpers } from "../../objects/objectSaveHelpers";
import { questionLoadHelpers } from "../../../helpers/questionLoadHelpers";
import { blankForms } from "../../../helpers/blankForms";

import QuestionBase from "../../../QuestionBase";
import ObjectCard from "../../objects/ObjectCard";
import InputRadioMultiple from "../../../inputs/InputRadioMutiple";
import AssetMainFull from "../../../../fieldsets/sub/asset/AssetMainFull";
import AssetMainShort from "../../../../fieldsets/sub/asset/AssetMainShort";
import AssetEstatePlanning from "../../../../fieldsets/sub/asset/AssetEstatePlanning";
import AssetMortgage from "../../../../fieldsets/sub/asset/AssetMortgage";

export default {
  name: 'AssetsMain',
  components: {
    AssetMortgage,
    AssetEstatePlanning,
    AssetMainShort,
    AssetMainFull,
    InputRadioMultiple,
    ObjectCard,
    QuestionBase
  },
  mixins: [objectSaveHelpers, questionLoadHelpers, assetOptions, blankForms],
  props: {
    value: {
      type: Array,
      required: false
    },
    type: {
      type: String,
      default: 'main'
    },
    title: {
      type: String,
      default: 'Which of the below assets do you own?'
    },
    subTitle: {
      type: String,
      default: 'These are just the most common types of asset, you can add other types of assets in the next section.',
    },
    tip: {
      type: String,
      default: null
    }
  },
  mounted () {
    this.setAssetRadioInput()
  },
  data() {
    return {
      question: {
        title:  this.title,
        subTitle:  this.subTitle,
        tip: this.tip,
      },
      db: {
        saveLocation: 'client_asset',
        saveField: 'assets_main',
        deleteLocation: 'client_asset',
        loadLocation: `client_assets_${this.type}`,
        syncMultipleObjectOnSave: true,
        fetchObjectsOnSave: false
      },
      args: {
        skippable: false,
        required: false,
        customErrorMessageNewOpenForm: "Save asset before proceeding, by clicking 'confirm'"
      },
      serverErrors: [],
      errorsPost: {
        residentialHome: {},
        savings: {},
        pensions: {},
        vehicles: {},
        householdContents: {},
        shares: {}
      },
      jsErrors: [],
      skipped: false,
      forms: { // forms and not form: this is required for open form validation
        residentialHome: null,
        investmentProperty: null,
        secondHome: null,
        savings: null,
        pensions: null,
        vehicles: null,
        householdContents: null,
        shares: null
      },
      assetsMainInput: [],
      errors: {}
    }
  },
  computed: {
    data: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    },
    formType () {
      if (this.type === 'mortgage') return 'AssetMortgage'
      if (this.type === 'estate_planning') return 'AssetEstatePlanning'
      return 'AssetMainShort'
    },
    options () {
      return this.type ==='mortgage'? this.assetsMainMortgageOptions : this.assetsMainOptions
    }
  },
  methods: {
    setForm(assetType) {
      if (this.assetsMainInput.includes(assetType)) {
        if (assetType === 'Residential Home') {
          this.forms.residentialHome = this.clone(this.assetBlankForm)
          this.forms.residentialHome.assetType = 'Residential Home'
        }
        else if (assetType === 'Second Home') {
          this.forms.secondHome = this.clone(this.assetBlankForm)
          this.forms.secondHome.assetType = 'Second Home'
        }
        else if (assetType === 'Investment Property') {
          this.forms.investmentProperty = this.clone(this.assetBlankForm)
          this.forms.investmentProperty.assetType = 'Investment Property'
        }
        else if (assetType === 'Savings') {
          this.forms.savings = this.clone(this.assetBlankForm)
          this.forms.savings.assetType = 'Savings'
        }
        else if (assetType === 'Pensions') {
          this.forms.pensions = this.clone(this.assetBlankForm)
          this.forms.pensions.assetType = 'Pensions'
        }
        else if (assetType === 'Vehicles') {
          this.forms.vehicles = this.clone(this.assetBlankForm)
          this.forms.vehicles.assetType = 'Vehicles'
        }
        else if (assetType === 'Household Contents') {
          this.forms.householdContents = this.clone(this.assetBlankForm)
          this.forms.householdContents.assetType = 'Household Contents'
        }
        else if (assetType === 'Shares') {
          this.forms.shares = this.clone(this.assetBlankForm)
          this.forms.shares.assetType = 'Shares'
        }
      } else {
        if (assetType === 'Residential Home') {
          this.forms.residentialHome = null
        }
        else if (assetType === 'Second Home') {
          this.forms.secondHome = null
        }
        else if (assetType === 'Investment Property') {
          this.forms.investmentProperty = null
        }
        else if (assetType === 'Savings') {
          this.forms.savings = null
        }
        else if (assetType === 'Pensions') {
          this.forms.pensions = null
        }
        else if (assetType === 'Vehicles') {
          this.forms.vehicles = null
        }
        else if (assetType === 'Household Contents') {
          this.forms.householdContents = null
        }
        else if (assetType === 'Shares') {
          this.forms.shares = null
        }
      }
    },
    removeForm (value) {
      this.forms[value] = null
      this.errorsPost[value] = {}

      if (value === 'residentialHome') {
        this.removeFromMainInput('Residential Home')
      } else if (value === 'savings') {
        this.removeFromMainInput('Second Home')
      } else if (value === 'secondHome') {
        this.removeFromMainInput('Investment Property')
      } else if (value === 'investmentProperty') {
        this.removeFromMainInput('Savings')
      } else if (value === 'pensions') {
        this.removeFromMainInput('Pensions')
      } else if (value === 'vehicles') {
        this.removeFromMainInput('Vehicles')
      } else if (value === 'householdContents') {
        this.removeFromMainInput('Household Contents')
      } else if (value === 'shares') {
        this.removeFromMainInput('Shares')
      }
    },
    setAssetRadioInput() {
      for (let i = 0; i < this.assetTypeOptions.length; i++) {
        if (this.data.find(asset => asset.assetType === this.assetTypeOptions[i].value)) {
          this.assetsMainInput.push(this.assetTypeOptions[i].value)
        }
      }

    },
    removeFromMainInput (value) {
      let index = this.assetsMainInput.findIndex(option => option === value)
      if (index >= 0) this.assetsMainInput.splice(index, 1)
    }
  }
}
</script>
