export const policyOptions = {
    data() {
        return {
            policyTypeOptions: [
                  {
                    label: 'Family Income Benefit',
                    value: 'Family Income Benefit'
                  },
                  {
                    label: 'Accident, Sickness & Unemployment',
                    value: 'Accident, Sickness & Unemployment'
                  },
                  {
                    label: 'Accidental Death',
                    value: 'Accidental Death'
                  },
                  {
                    label: 'Redundancy',
                    value: 'Redundancy'
                  },
                  {
                    label: 'Menu Plan',
                    value: 'Menu Plan'
                  },
              ],

              policiesMainOptions: [
                {
                    label: 'Life Cover',
                    value: 'Life Cover'
                  },
                  {
                    label: 'Critical Illness',
                    value: 'Critical Illness'
                  },
                  {
                    label: 'Life & Critical Illness',
                    value: 'Life & Critical Illness'
                  },
                  {
                    label: 'Whole of Life',
                    value: 'Whole of Life'
                  },
                  {
                    label: 'Income Protection',
                    value: 'Income Protection'
                  }
            ],
              policyTrustStatusOptions: [
                {
                  label: 'Yes',
                  value: 'Yes'
                },
                {
                  label: 'No',
                  value: 'No'
                },
                {
                  label: 'Unsure',
                  value: 'Unsure'
                }
              ],
            policyPremiumTypeOptions: [
                {
                  label: 'Reviewable',
                  value: 'Reviewable'
                },
                {
                  label: 'Fixed',
                  value: 'Fixed'
                }
              ],
            policyCoverTypeOptions: [
                {
                  label: 'Decreasing',
                  value: 'Decreasing'
                },
                {
                  label: 'Level',
                  value: 'Level'
                }
              ],
        }
    }
}
