<template>
  <div>

    <JobTitle
        v-model="form.incomeEmployedJobTitle"
        :errors-post="errorsPost.incomeEmployedJobTitle"
        :object-id="objectId"
        :no-save="noSave"
    />

    <AmountCurrent
        v-model="form.incomeCommonAmountCurrent"
        :errors-post="errorsPost.incomeCommonAmountCurrent"
        :object-id="objectId"
        :no-save="noSave"
        title="Profit Amount (Latest Year)"
    />

    <AmountPrevious
        v-model="form.incomeCommonAmountPrevious"
        :errors-post="errorsPost.incomeCommonAmountPrevious"
        :object-id="objectId"
        :no-save="noSave"
        title="Profit Amount (Previous Year)"
    />

    <hr>

    <CompanyName
        v-model="form.incomeCommonBusinessName"
        :errors-post="errorsPost.incomeCommonBusinessName"
        :object-id="objectId"
        :no-save="noSave"
    />

    <BusinessAddress
        v-model="form.incomeCommonBusinessAddress"
        :form="form"
        :errors-post="errorsPost.incomeCommonBusinessAddress"
        :object-id="objectId"
        :no-save="noSave"
        title="Company address"
    />

    <EmployerSector
        v-model="form.incomeCommonBusinessSector"
        :errors-post="errorsPost.incomeCommonBusinessSector"
        :object-id="objectId"
        :no-save="noSave"
    />

  </div>
</template>

<script>
import BusinessAddress from "@/components/common/questionnaires/question/questions/clientIncome/BusinessAddress";
import CompanyName from "@/components/common/questionnaires/question/questions/clientPerson/professional/CompanyName";
import EmployerSector from "@/components/common/questionnaires/question/questions/clientIncome/EmployerSector";
import JobTitle from "@/components/common/questionnaires/question/questions/clientIncome/JobTitle";
import AmountCurrent from "@/components/common/questionnaires/question/questions/clientIncome/AmountCurrent";
import AmountPrevious from "@/components/common/questionnaires/question/questions/clientIncome/AmountPrevious";

export default {
  name: 'Contractor',
  components: {
    AmountPrevious,
    AmountCurrent,
    JobTitle,
    EmployerSector,
    CompanyName,
    BusinessAddress,

  },
  props: {
    value: {
      type: Object,
      required: true
    },
    objectId: {
      type: Number,
      required: false
    },
    noSave: {
      type: Boolean,
      default: false
    },
    errorsPost: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
    }
  },
  computed: {
    form: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    }
  }
}

</script>
