<template>
  <div
      class="question-multipart-wrapper d-flex flex-wrap align-items-end"
  >
    <QuestionBase
        :question="question"
        :valid="!!data"
        :errors="[...errorsPost, ...serverErrors, ...jsErrors]"
    >
      <InputDateSplit
          v-if="data || data === null"
          v-model="data"
          :db="db"
          :args="args"
          :skipped="skipped"
          :no-save="noSave"
          @skipped="skipped=$event"
          @serverErrors="serverErrors=$event"
          @jsErrors="jsErrors=$event"
      />
    </QuestionBase>
  </div>
</template>

<script>
import { questionLoadHelpers } from "../../helpers/questionLoadHelpers";
import {saveHelpers} from "@/views/questionnaires/saveHelpers";

import QuestionBase from "../../QuestionBase";
import InputDateSplit from "../../inputs/InputDateSplit";

export default {
  name: 'ProductEndDateMortgage',
  mixins: [saveHelpers, questionLoadHelpers],
  components: {
    InputDateSplit,
    QuestionBase
  },
  props: {
    value: {
      type: String,
      required: false
    },
    errorsPost: {
      type: Array,
      default: () => {
        return []
      }
    },
    noSave: {
      type: Boolean,
      default: false
    },
    objectId: {
      type: Number,
      required: false
    },
  },
  computed: {
    data: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    }
  },
  data() {
    return {
      question: {
        title: 'When does the mortgage product end?',
        subTitle: 'Mortgage deals usually end after between 2 and 5 years.',
        tip: null
      },
      db: {
        saveLocation: 'client_liability',
        saveField: 'liabilityProductEndDate',
        objectId: this.objectId
      },
      args: {
        skippable: false,
        required: true,
      },
      serverErrors: [],
      jsErrors: [],
      skipped: false,
    }
  }
}
</script>