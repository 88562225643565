<template>
  <QuestionBase :errors="[...serverErrors, ...jsErrors]" :question="question" :valid="data !== null">
    <InputRadio
        v-model="data"
        :args="args"
        :db="db"
        :no-save="false"
        :options="booleanYesNo"
        :server-errors="serverErrors"
        :disabled="!!liabilities.length"
        @jsErrors="jsErrors=$event"
        ref="input"
    />
  </QuestionBase>
</template>

<script>
import {questionLoadHelpers} from "../../../helpers/questionLoadHelpers";
import {general} from "../../../options/general";

import QuestionBase from "../../../QuestionBase";
import InputRadio from "../../../inputs/InputRadio";

export default {
  name: 'LiabilitiesMortgageYN',
  mixins: [general, questionLoadHelpers],
  components: {
    InputRadio,
    QuestionBase
  },
  props: {
    value: {
      type: [Boolean],
      required: false
    },
    liabilities: {
      type: Array,
      required: true
    }
  },
  mounted() {
    // set initial question value (yes/no) based on assets
    if (this.liabilities.length && !this.data) {
      this.$refs.input.data = true
    }
  },
  computed: {
    data: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    }
  },
  data() {
    return {
      question: {
        title: 'Do you currently have any mortgages debts?',
        subTitle: "If you have a mortgage on any property, residential or Buy-to-Let, please answer 'Yes'.",
        tip: null,
      },
      db: {
        saveLocation: 'client_client',
        saveField: 'mortgage_debts'
      },
      args: {
        skippable: false,
        required: true,
      },
      serverErrors: [],
      jsErrors: [],
      skipped: false,
    }
  }
}
</script>