export const mortgageSurveyOptions = {
    data () {
        return {
          optionsExisting: [
            {
              label: 'Sold Subject to Contact',
              value: 'Sold Subject to Contact'
            },
            {
              label: 'To Be Sold (On the market)',
              value: 'To Be Sold (On the market)'
            },
            {
              label: 'To Be Sold (Not yet on the market)',
              value: 'To Be Sold (Not yet on the market)'
            },
            {
              label: 'To Be Retained (With Re-Finance)',
              value: 'To Be Retained (With Re-Finance)'
            },
            {
              label: 'To Be Retained (No Re-Finance)',
              value: 'To Be Retained (No Re-Finance)'
            }
          ],
          optionsStillLooking: [
            {
              label: 'ASAP',
              value: 'ASAP'
            },
            {
              label: '3-6 Months',
              value: '3-6 Months'
            },
            {
              label: 'Within 6 Months',
              value: 'Within 6 Months'
            },
            {
              label: '6-12 Months',
              value: '6-12 Months'
            },
            {
              label: 'One Year Onwards',
              value: 'One Year Onwards'
            }
          ],
          optionsDeposit: [
            {
              label: 'Equity',
              value: 'Equity'
            },
            {
              label: 'Remortgage',
              value: 'Remortgage'
            },
            {
              label: 'Savings',
              value: 'Savings'
            },
            {
              label: 'Gift (Family)',
              value: 'Gift (Family)'
            },
            {
              label: 'Gift (Other)',
              value: 'Gift (Other)'
            },
            {
              label: 'Loan (Family)',
              value: 'Loan (Family)'
            },
            {
              label: 'Loan (Bank)',
              value: 'Loan (Bank)'
            },
            {
              label: 'Loan (Other)',
              value: 'Loan (Other)'
            }
          ],
          optionsLender: [
            {
              label: 'AA Mortgages',
              value: 'AA Mortgages'
            },
            {
              label: 'Accord Mortgages',
              value: 'Accord Mortgages'
            },
            {
              label: 'Ahli United Bank',
              value: 'Ahli United Bank'
            },
            {
              label: 'Al Rayan Bank',
              value: 'Al Rayan Bank'
            },
            {
              label: 'Aldermore',
              value: 'Aldermore'
            },
            {
              label: 'Atom Bank',
              value: 'Atom Bank'
            },
            {
              label: 'Axis Bank',
              value: 'Axis Bank'
            },
            {
              label: 'Bank of China',
              value: 'Bank of China'
            },
            {
              label: 'Bank of Ireland',
              value: 'Bank of Ireland'
            },
            {
              label: 'Barclays',
              value: 'Barclays'
            },
            {
              label: 'Bath Building Society',
              value: 'Bath Building Society'
            },
            {
              label: 'Beverly Building Society',
              value: 'Beverly Building Society'
            },
            {
              label: 'Bluestone',
              value: 'Bluestone'
            },
            {
              label: 'BM Solutions',
              value: 'BM Solutions'
            },
            {
              label: 'Brown Shipley Private Bank',
              value: 'Brown Shipley Private Bank'
            },
            {
              label: 'Buckinghamshire Building Society',
              value: 'Buckinghamshire Building Society'
            },
            {
              label: 'BuildLoan',
              value: 'BuildLoan'
            },
            {
              label: 'Cambridge Building Society',
              value: 'Cambridge Building Society'
            },
            {
              label: 'Chelsea Building Society',
              value: 'Chelsea Building Society'
            },
            {
              label: 'Chorley BS',
              value: 'Chorley BS'
            },
            {
              label: 'Clydesdale',
              value: 'Clydesdale'
            },
            {
              label: 'Co-operative Financial Services',
              value: 'Co-operative Financial Services'
            },
            {
              label: 'Coutts & Co',
              value: 'Coutts & Co'
            },
            {
              label: 'Coventry Building Society',
              value: 'Coventry Building Society'
            },
            {
              label: 'Credit Suisse',
              value: 'Credit Suisse'
            },
            {
              label: 'Danske Bank',
              value: 'Danske Bank'
            },
            {
              label: 'Darlington Building Society',
              value: 'Darlington Building Society'
            },
            {
              label: 'Dashly',
              value: 'Dashly'
            },
            {
              label: 'Digital Mortgages',
              value: 'Digital Mortgages'
            },
            {
              label: 'Dudley Building Society',
              value: 'Dudley Building Society'
            },
            {
              label: 'Earl Shilton Building Society',
              value: 'Earl Shilton Building Society'
            },
            {
              label: 'Ecology Building Society',
              value: 'Ecology Building Society'
            },
            {
              label: 'Family Building Society',
              value: 'Family Building Society'
            },
            {
              label: 'First Direct',
              value: 'First Direct'
            },
            {
              label: 'First Trust Bank',
              value: 'First Trust Bank'
            },
            {
              label: 'Fleet Mortgages',
              value: 'Fleet Mortgages'
            },
            {
              label: 'Foundation Home Loans',
              value: 'Foundation Home Loans'
            },
            {
              label: 'Furness',
              value: 'Furness'
            },
            {
              label: 'Godiva Mortgages',
              value: 'Godiva Mortgages'
            },
            {
              label: 'Habito',
              value: 'Habito'
            },
            {
              label: 'Halifax',
              value: 'Halifax'
            },
            {
              label: 'Handelsbanken',
              value: 'Handelsbanken'
            },
            {
              label: 'Hanley Economic Building Society',
              value: 'Hanley Economic Building Society'
            },
            {
              label: 'Harpenden Building Society',
              value: 'Harpenden Building Society'
            },
            {
              label: 'Harrods Bank',
              value: 'Harrods Bank'
            },
            {
              label: 'Hinckley & Rugby Building Society',
              value: 'Hinckley & Rugby Building Society'
            },
            {
              label: 'Hodge Lifetime',
              value: 'Hodge Lifetime'
            },
            {
              label: 'Holmesdale Building Society',
              value: 'Holmesdale Building Society'
            },
            {
              label: 'HSBC',
              value: 'HSBC'
            },
            {
              label: 'InterBay Commercial',
              value: 'InterBay Commercial'
            },
            {
              label: 'Investec Specialist Bank',
              value: 'Investec Specialist Bank'
            },
            {
              label: 'Ipswich Building Society',
              value: 'Ipswich Building Society'
            },
            {
              label: 'Kensington Mortgages',
              value: 'Kensington Mortgages'
            },
            {
              label: 'Kent Reliance',
              value: 'Kent Reliance'
            },
            {
              label: 'Keystone Property Finance',
              value: 'Keystone Property Finance'
            },
            {
              label: 'L&C',
              value: 'L&C'
            },
            {
              label: 'Landbay',
              value: 'Landbay'
            },
            {
              label: 'Leeds Building Society',
              value: 'Leeds Building Society'
            },
            {
              label: 'Leek United Building Society',
              value: 'Leek United Building Society'
            },
            {
              label: 'Lloyds',
              value: 'Lloyds'
            },
            {
              label: 'Loughborough Building Society',
              value: 'Loughborough Building Society'
            },
            {
              label: 'M&S Bank',
              value: 'M&S Bank'
            },
            {
              label: 'Magellan',
              value: 'Magellan'
            },
            {
              label: 'Mansfield Building Society',
              value: 'Mansfield Building Society'
            },
            {
              label: 'Market Harborough Building Society',
              value: 'Market Harborough Building Society'
            },
            {
              label: 'Marsden Building Society',
              value: 'Marsden Building Society'
            },
            {
              label: 'Masthaven Bank',
              value: 'Masthaven Bank'
            },
            {
              label: 'Melton Mowbray Building Society',
              value: 'Melton Mowbray Building Society'
            },
            {
              label: 'Metro Bank',
              value: 'Metro Bank'
            },
            {
              label: 'Mojo Mortgages',
              value: 'Mojo Mortgages'
            },
            {
              label: 'Monmouthshire Building Society',
              value: 'Monmouthshire Building Society'
            },
            {
              label: 'Mortgage Gym',
              value: 'Mortgage Gym'
            },
            {
              label: 'Mortgage Trust',
              value: 'Mortgage Trust'
            },
            {
              label: 'Nationwide Building Society',
              value: 'Nationwide Building Society'
            },
            {
              label: 'NatWest',
              value: 'NatWest'
            },
            {
              label: 'NatWest International',
              value: 'NatWest International'
            },
            {
              label: 'New Street Mortgages',
              value: 'New Street Mortgages'
            },
            {
              label: 'Newbury Building Society',
              value: 'Newbury Building Society'
            },
            {
              label: 'Newcastle Building Society',
              value: 'Newcastle Building Society'
            },
            {
              label: 'Nottingham Building Society',
              value: 'Nottingham Building Society'
            },
            {
              label: 'OneSavings Bank',
              value: 'OneSavings Bank'
            },
            {
              label: 'Paragon Mortgages',
              value: 'Paragon Mortgages'
            },
            {
              label: 'Penrith Building Society',
              value: 'Penrith Building Society'
            },
            {
              label: 'Pepper Homeloans',
              value: 'Pepper Homeloans'
            },
            {
              label: 'Platform Home Loans',
              value: 'Platform Home Loans'
            },
            {
              label: 'Post Office',
              value: 'Post Office'
            },
            {
              label: 'Precise Mortgages',
              value: 'Precise Mortgages'
            },
            {
              label: 'Principality',
              value: 'Principality'
            },
            {
              label: 'Progressive Building Society',
              value: 'Progressive Building Society'
            },
            {
              label: 'Proportunity',
              value: 'Proportunity'
            },
            {
              label: 'RateSwitch',
              value: 'RateSwitch'
            },
            {
              label: 'Royal Bank of Scotland',
              value: 'Royal Bank of Scotland'
            },
            {
              label: 'Saffron Building Society',
              value: 'Saffron Building Society'
            },
            {
              label: 'Sainsburys Bank',
              value: 'Sainsburys Bank'
            },
            {
              label: 'Santander',
              value: 'Santander'
            },
            {
              label: 'Scottish Building Society',
              value: 'Scottish Building Society'
            },
            {
              label: 'Scottish Widows Bank',
              value: 'Scottish Widows Bank'
            },
            {
              label: 'Secure Trust Bank',
              value: 'Secure Trust Bank'
            },
            {
              label: 'Shawbrook Bank',
              value: 'Shawbrook Bank'
            },
            {
              label: 'Skipton Building society',
              value: 'Skipton Building society'
            },
            {
              label: 'Skipton International',
              value: 'Skipton International'
            },
            {
              label: 'Stafford Railway Building Society',
              value: 'Stafford Railway Building Society'
            },
            {
              label: 'State Bank of India',
              value: 'State Bank of India'
            },
            {
              label: 'Swansea Building Society',
              value: 'Swansea Building Society'
            },
            {
              label: 'Teachers Building Society',
              value: 'Teachers Building Society'
            },
            {
              label: 'Tesco Bank',
              value: 'Tesco Bank'
            },
            {
              label: 'The Co-operative Bank',
              value: 'The Co-operative Bank'
            },
            {
              label: 'The Cumberland',
              value: 'The Cumberland'
            },
            {
              label: 'The Mortgage Lender',
              value: 'The Mortgage Lender'
            },
            {
              label: 'The Mortgage Works',
              value: 'The Mortgage Works'
            },
            {
              label: 'Tipton & Coseley Building Society',
              value: 'Tipton & Coseley Building Society'
            },
            {
              label: 'Together Money',
              value: 'Together Money'
            },
            {
              label: 'Trussle',
              value: 'Trussle'
            },
            {
              label: 'TSB',
              value: 'TSB'
            },
            {
              label: 'UBS Private Bank',
              value: 'UBS Private Bank'
            },
            {
              label: 'Ulster Bank',
              value: 'Ulster Bank'
            },
            {
              label: 'Vernon Building Society',
              value: 'Vernon Building Society'
            },
            {
              label: 'Vida Homeloans',
              value: 'Vida Homeloans'
            },
            {
              label: 'Virgin Money',
              value: 'Virgin Money'
            },
            {
              label: 'West Bromwich Building Society',
              value: 'West Bromwich Building Society'
            },
            {
              label: 'Yorkshire Bank',
              value: 'Yorkshire Bank'
            },
            {
              label: 'Yorkshire Building Society',
              value: 'Yorkshire Building Society'
            }
          ],
          optionsBankBuildingSociety: [
            {
              label: 'Ahli United Bank',
              value: 'Ahli United Bank'
            },
            {
              label: 'Al Rayan Bank',
              value: 'Al Rayan Bank'
            },
            {
              label: 'Atom Bank',
              value: 'Atom Bank'
            },
            {
              label: 'Axis Bank',
              value: 'Axis Bank'
            },
            {
              label: 'Bank of China',
              value: 'Bank of China'
            },
            {
              label: 'Bank of Ireland',
              value: 'Bank of Ireland'
            },
            {
              label: 'Barclays',
              value: 'Barclays'
            },
            {
              label: 'Bath Building Society',
              value: 'Bath Building Society'
            },
            {
              label: 'Beverly Building Society',
              value: 'Beverly Building Society'
            },
            {
              label: 'Bluestone',
              value: 'Bluestone'
            },
            {
              label: 'Brown Shipley Private Bank',
              value: 'Brown Shipley Private Bank'
            },
            {
              label: 'Buckinghamshire Building Society',
              value: 'Buckinghamshire Building Society'
            },
            {
              label: 'Cambridge Building Society',
              value: 'Cambridge Building Society'
            },
            {
              label: 'Chelsea Building Society',
              value: 'Chelsea Building Society'
            },
            {
              label: 'Chorley BS',
              value: 'Chorley BS'
            },
            {
              label: 'Clydesdale',
              value: 'Clydesdale'
            },
            {
              label: 'Coutts & Co',
              value: 'Coutts & Co'
            },
            {
              label: 'Coventry Building Society',
              value: 'Coventry Building Society'
            },
            {
              label: 'Credit Suisse',
              value: 'Credit Suisse'
            },
            {
              label: 'Danske Bank',
              value: 'Danske Bank'
            },
            {
              label: 'Darlington Building Society',
              value: 'Darlington Building Society'
            },
            {
              label: 'Dashly',
              value: 'Dashly'
            },
            {
              label: 'Dudley Building Society',
              value: 'Dudley Building Society'
            },
            {
              label: 'Earl Shilton Building Society',
              value: 'Earl Shilton Building Society'
            },
            {
              label: 'Ecology Building Society',
              value: 'Ecology Building Society'
            },
            {
              label: 'Family Building Society',
              value: 'Family Building Society'
            },
            {
              label: 'First Direct',
              value: 'First Direct'
            },
            {
              label: 'First Trust Bank',
              value: 'First Trust Bank'
            },
            {
              label: 'Fleet Mortgages',
              value: 'Fleet Mortgages'
            },
            {
              label: 'Foundation Home Loans',
              value: 'Foundation Home Loans'
            },
            {
              label: 'Furness',
              value: 'Furness'
            },
            {
              label: 'Habito',
              value: 'Habito'
            },
            {
              label: 'Halifax',
              value: 'Halifax'
            },
            {
              label: 'Handelsbanken',
              value: 'Handelsbanken'
            },
            {
              label: 'Hanley Economic Building Society',
              value: 'Hanley Economic Building Society'
            },
            {
              label: 'Harpenden Building Society',
              value: 'Harpenden Building Society'
            },
            {
              label: 'Harrods Bank',
              value: 'Harrods Bank'
            },
            {
              label: 'Hinckley & Rugby Building Society',
              value: 'Hinckley & Rugby Building Society'
            },
            {
              label: 'Hodge Lifetime',
              value: 'Hodge Lifetime'
            },
            {
              label: 'Holmesdale Building Society',
              value: 'Holmesdale Building Society'
            },
            {
              label: 'HSBC',
              value: 'HSBC'
            },
            {
              label: 'InterBay Commercial',
              value: 'InterBay Commercial'
            },
            {
              label: 'Investec Specialist Bank',
              value: 'Investec Specialist Bank'
            },
            {
              label: 'Ipswich Building Society',
              value: 'Ipswich Building Society'
            },
            {
              label: 'Kensington Mortgages',
              value: 'Kensington Mortgages'
            },
            {
              label: 'Kent Reliance',
              value: 'Kent Reliance'
            },
            {
              label: 'Keystone Property Finance',
              value: 'Keystone Property Finance'
            },
            {
              label: 'L&C',
              value: 'L&C'
            },
            {
              label: 'Landbay',
              value: 'Landbay'
            },
            {
              label: 'Leeds Building Society',
              value: 'Leeds Building Society'
            },
            {
              label: 'Leek United Building Society',
              value: 'Leek United Building Society'
            },
            {
              label: 'Lloyds',
              value: 'Lloyds'
            },
            {
              label: 'Loughborough Building Society',
              value: 'Loughborough Building Society'
            },
            {
              label: 'M&S Bank',
              value: 'M&S Bank'
            },
            {
              label: 'Magellan',
              value: 'Magellan'
            },
            {
              label: 'Mansfield Building Society',
              value: 'Mansfield Building Society'
            },
            {
              label: 'Market Harborough Building Society',
              value: 'Market Harborough Building Society'
            },
            {
              label: 'Marsden Building Society',
              value: 'Marsden Building Society'
            },
            {
              label: 'Masthaven Bank',
              value: 'Masthaven Bank'
            },
            {
              label: 'Melton Mowbray Building Society',
              value: 'Melton Mowbray Building Society'
            },
            {
              label: 'Metro Bank',
              value: 'Metro Bank'
            },
            {
              label: 'Monmouthshire Building Society',
              value: 'Monmouthshire Building Society'
            },
            {
              label: 'Mortgage Trust',
              value: 'Mortgage Trust'
            },
            {
              label: 'Nationwide Building Society',
              value: 'Nationwide Building Society'
            },
            {
              label: 'NatWest',
              value: 'NatWest'
            },
            {
              label: 'NatWest International',
              value: 'NatWest International'
            },
            {
              label: 'New Street Mortgages',
              value: 'New Street Mortgages'
            },
            {
              label: 'Newbury Building Society',
              value: 'Newbury Building Society'
            },
            {
              label: 'Newcastle Building Society',
              value: 'Newcastle Building Society'
            },
            {
              label: 'Nottingham Building Society',
              value: 'Nottingham Building Society'
            },
            {
              label: 'OneSavings Bank',
              value: 'OneSavings Bank'
            },
            {
              label: 'Paragon Mortgages',
              value: 'Paragon Mortgages'
            },
            {
              label: 'Penrith Building Society',
              value: 'Penrith Building Society'
            },
            {
              label: 'Platform Home Loans',
              value: 'Platform Home Loans'
            },
            {
              label: 'Post Office',
              value: 'Post Office'
            },
            {
              label: 'Precise Mortgages',
              value: 'Precise Mortgages'
            },
            {
              label: 'Principality',
              value: 'Principality'
            },
            {
              label: 'Progressive Building Society',
              value: 'Progressive Building Society'
            },
            {
              label: 'Proportunity',
              value: 'Proportunity'
            },
            {
              label: 'RateSwitch',
              value: 'RateSwitch'
            },
            {
              label: 'Royal Bank of Scotland',
              value: 'Royal Bank of Scotland'
            },
            {
              label: 'Saffron Building Society',
              value: 'Saffron Building Society'
            },
            {
              label: 'Sainsburys Bank',
              value: 'Sainsburys Bank'
            },
            {
              label: 'Santander',
              value: 'Santander'
            },
            {
              label: 'Scottish Building Society',
              value: 'Scottish Building Society'
            },
            {
              label: 'Scottish Widows Bank',
              value: 'Scottish Widows Bank'
            },
            {
              label: 'Secure Trust Bank',
              value: 'Secure Trust Bank'
            },
            {
              label: 'Shawbrook Bank',
              value: 'Shawbrook Bank'
            },
            {
              label: 'Skipton Building society',
              value: 'Skipton Building society'
            },
            {
              label: 'Skipton International',
              value: 'Skipton International'
            },
            {
              label: 'Stafford Railway Building Society',
              value: 'Stafford Railway Building Society'
            },
            {
              label: 'State Bank of India',
              value: 'State Bank of India'
            },
            {
              label: 'Swansea Building Society',
              value: 'Swansea Building Society'
            },
            {
              label: 'Teachers Building Society',
              value: 'Teachers Building Society'
            },
            {
              label: 'Tesco Bank',
              value: 'Tesco Bank'
            },
            {
              label: 'The Co-operative Bank',
              value: 'The Co-operative Bank'
            },
            {
              label: 'The Cumberland',
              value: 'The Cumberland'
            },
            {
              label: 'The Mortgage Lender',
              value: 'The Mortgage Lender'
            },
            {
              label: 'The Mortgage Works',
              value: 'The Mortgage Works'
            },
            {
              label: 'Tipton & Coseley Building Society',
              value: 'Tipton & Coseley Building Society'
            },
            {
              label: 'Together Money',
              value: 'Together Money'
            },
            {
              label: 'Trussle',
              value: 'Trussle'
            },
            {
              label: 'TSB',
              value: 'TSB'
            },
            {
              label: 'UBS Private Bank',
              value: 'UBS Private Bank'
            },
            {
              label: 'Ulster Bank',
              value: 'Ulster Bank'
            },
            {
              label: 'Vernon Building Society',
              value: 'Vernon Building Society'
            },
            {
              label: 'Vida Homeloans',
              value: 'Vida Homeloans'
            },
            {
              label: 'Virgin Money',
              value: 'Virgin Money'
            },
            {
              label: 'West Bromwich Building Society',
              value: 'West Bromwich Building Society'
            },
            {
              label: 'Yorkshire Bank',
              value: 'Yorkshire Bank'
            },
            {
              label: 'Yorkshire Building Society',
              value: 'Yorkshire Building Society'
            }
          ],
          optionsOwner: [
            {
              label: 'Sole Name',
              value: 'Sole Name'
            },
            {
              label: 'Joint Names',
              value: 'Joint Names'
            },
            {
              label: 'Limited Company',
              value: 'Limited Company'
            },
            {
              label: 'Other',
              value: 'Other'
            }
          ],
          optionsType: [
            {
              label: 'Purchase',
              value: 'Purchase'
            },
            {
              label: 'Remortgage',
              value: 'Remortgage'
            }
          ],
          optionsUsage: [
            {
              label: 'Residential',
              value: 'Residential'
            },
            {
              label: 'Buy to Let',
              value: 'Buy to Let'
            },
            {
              label: 'Holiday Let',
              value: 'Holiday Let'
            },
            {
              label: 'Second Home',
              value: 'Second Home'
            },
            {
              label: 'Dependent Relative',
              value: 'Dependent Relative'
            },
            {
              label: 'Other',
              value: 'Other'
            }
          ],
          optionsPropertyFound: [
            {
              label: 'Offer Agreed',
              value: 'Offer Agreed'
            },
            {
              label: 'To Negotiate',
              value: 'To Negotiate'
            },
            {
              label: 'Still Looking',
              value: 'Still Looking'
            }
          ],
          optionsExistingEquity: [
            {
              label: 'No',
              value: 'No'
            },
            {
              label: 'Help to Buy',
              value: 'Help to Buy'
            },
            {
              label: 'Other Scheme',
              value: 'Other Scheme'
            }
          ]
        }
    }
}
