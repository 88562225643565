export const commitmentOptions = {
    data () {
        return {
            commitmentTypeOptions: [
                {
                    label: 'Rent',
                    value: 'Rent'
                },
                {
                    label: 'Water',
                    value: 'Water'
                },
                {
                    label: 'Gas / Electricity',
                    value: 'Gas / Electricity'
                },
                {
                    label: 'Food / Household',
                    value: 'Food / Household'
                },
                {
                    label: 'Internet / TV',
                    value: 'Internet / TV'
                },
                {
                    label: 'Council Tax',
                    value: 'Council Tax'
                },
                {
                    label: 'Nursery/Childminder/Childcare',
                    value: 'Childcare'
                },
                {
                    label: 'Travel / Fuel',
                    value: 'Travel / Fuel'
                },
                {
                    label: 'Mobile Phone',
                    value: 'Mobile Phone'
                },
                {
                    label: 'Pension',
                    value: 'Pension'
                },
                {
                    label: 'Hobbies',
                    value: 'Hobbies'
                },
                {
                    label: 'Regular Savings',
                    value: 'Regular Savings'
                }
            ],
        }
    }
}
