<template>
  <QuestionBase :question="question" :errors="[...serverErrors, ...jsErrors]" :valid="data !== null">
   <InputSelect
       v-model="data"
       :options="nationalityOptions"
       :db="db"
       :args="args"
       :skipped="skipped"
       :jsErrors="jsErrors"
       @skipped="skipped=$event"
       @serverErrors="serverErrors=$event"
       @jsErrors="jsErrors=$event"
   />
  </QuestionBase>
</template>

<script>
import { questionLoadHelpers } from "../../../helpers/questionLoadHelpers";
import { countryOptions } from "../../../options/country";

import QuestionBase from "../../../QuestionBase";
import InputSelect from "../../../inputs/InputSelect";

export default {
  name: 'Nationality',
  mixins: [countryOptions, questionLoadHelpers],
  components: {
    InputSelect,
    QuestionBase
  },
  props: {
    value: {
      type: String,
      required: false
    },
    saveLocation: {
      type: String,
      default: 'client_client'
    }
  },
  computed: {
    data: {
      set(value) {
        this.$emit('input', value)
      },
      get () {
        return this.value
      }
    }
  },
  data () {
    return {
      question: {
        title:  'What is your nationality?',
        subTitle:  null,
        tip: null,
      },
      db: {
        saveLocation: this.saveLocation,
        saveField: 'profileNationality'
      },
      args: {
        taggable: false,
        label: null,
        placeholder: 'Please select..',
        skippable: false,
        required: true,
        inputClass: 'field-66'
      },
      serverErrors: [],
      jsErrors: [],
      skipped: false,
    }
  }
}
</script>
