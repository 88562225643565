<template>
  <QuestionBase
      v-if="providerRequiredAssets.includes(assetType)"
      :errors="[...errorsPost, ...serverErrors, ...jsErrors]"
      :question="question"
      :valid="data !== null && !!data.length">
    <InputSelect
        v-model="data"
        :args="args"
        :db="db"
        :errors-post="errorsPost"
        :js-errors="jsErrors"
        :no-save="noSave"
        :options="optionsBankBuildingSociety"
        :skipped="skipped"
        :multiple="true"
        @jsErrors="jsErrors=$event"
        @serverErrors="serverErrors=$event"
        @skipped="skipped=$event"/>
  </QuestionBase>
</template>

<script>
import {questionLoadHelpers} from "../../helpers/questionLoadHelpers";
import {mortgageSurveyOptions} from "@/components/common/questionnaires/question/options/mortgageSurveyOptions";
import {assetOptions} from "@/components/common/questionnaires/question/options/assetOptions";

import QuestionBase from "../../QuestionBase";
import InputSelect from "../../inputs/InputSelect";

export default {
  name: 'Providers',
  mixins: [
    questionLoadHelpers,
    mortgageSurveyOptions,
      assetOptions
  ],
  components: {
    InputSelect,
    QuestionBase
  },
  props: {
    value: {
      type: Array,
      required: false
    },
    errorsPost: {
      type: Array,
      default: () => {
        return []
      }
    },
    noSave: {
      type: Boolean,
      default: false
    },
    objectId: {
      type: Number,
      required: false
    },
    assetType: {
      type: String,
      default: ''
    }
  },
  methods: {},
  computed: {
    data: {
      set(value) {
        this.$emit('input',
            value)
      },
      get() {
        return this.value
      }
    },
    question () {
      if (this.assetType === 'Savings') {
        return {
          title: 'Where do you hold savings accounts?',
          subTitle: 'Please select from the list below or type the name of the bank / building society. You can add multiple if necessary',
          tip: null
        }
      }
      if (this.assetType === 'Bank Accounts') {
        return {
          title: 'Where do you hold bank accounts?',
          subTitle: 'Please select from the list below or type the name of the bank. You can add multiple if necessary',
          tip: null
        }
      }
      if (this.assetType === 'Pensions') {
        return {
          title: 'Who do you have pensions with?',
          subTitle: 'Please select from the list below or type the name of the pension provider. You can add multiple if necessary',
          tip: null
        }
      }
      if (this.assetType === 'Shares') {
        return {
          title: 'What companies do you hold shares with / in?',
          subTitle: 'Please select from the list below or type the name of the company / provider. You can add multiple if necessary',
          tip: null
        }
      }
      if (this.assetType === 'Bonds') {
        return {
          title: 'Where do you hold bonds?',
          subTitle: 'Please select from the list below or type the name of provider. You can add multiple if necessary',
          tip: null
        }
      }
      return {
        title: 'Who is the provider?',
        subTitle: 'Please select from the list below or type the name of provider. You can add multiple if necessary',
        tip: null
      }
    },
  },
  data() {
    return {
      db: {
        saveLocation: 'client_asset',
        saveField: 'providers',
        objectId: this.objectId
      },
      args: {
        taggable: true,
        label: null,
        placeholder: 'Please select..',
        skippable: false,
        required: true,
        inputClass: 'field-100'
      },
      serverErrors: [],
      jsErrors: [],
      skipped: false
    }
  }
}
</script>
