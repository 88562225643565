export const addressOptions = {
    name: 'addressOptions',
    data () {
        return {
            addressOccupancyOptions: [
                {
                    label: 'Owner Occupier',
                    value: 'Owner Occupier',
                    selected: false
                },
                {
                    label: 'Renting Private',
                    value: 'Renting Private',
                    selected: false
                },
                {
                    label: 'Renting Local Authority',
                    value: 'Renting Local Authority',
                    selected: false
                },
                {
                    label: 'Living With Partner',
                    value: 'Living With Partner',
                    selected: false
                },
                {
                    label: 'Living With Friends',
                    value: 'Living With Friends',
                    selected: false
                },
                {
                    label: 'Living With Family',
                    value: 'Living With Family',
                    selected: false
                }
            ]
        }
    }
}