export const incomeOptions = {
    data () {
        return {
            incomeEmploymentOptions:[
                {
                    label: 'Employed',
                    value: 'Employed'
                },
                {
                    label: 'Company Director',
                    value: 'Company Director'
                },
                {
                    label: 'Contractor',
                    value: 'Contractor'
                },
                {
                    label: 'Sole Trader',
                    value: 'Sole Trader'
                },
                {
                    label: 'Partnership',
                    value: 'Partnership'
                },
                {
                    label: 'Limited Liability Partnership',
                    value: 'Limited Liability Partnership'
                },
                {
                    label: 'Student',
                    value: 'Student'
                },
                {
                    label: 'Retired',
                    value: 'Retired'
                },
                {
                    label: 'Homemaker',
                    value: 'Homemaker'
                },
                {
                    label: 'Unemployed',
                    value: 'Unemployed'
                }
            ],
            incomeTypeOptions: [
                {
                    label: 'Employed',
                    value: 'Employed'
                },
                {
                    label: 'Company Director',
                    value: 'Company Director'
                },
                {
                    label: 'Contractor',
                    value: 'Contractor'
                },
                {
                    label: 'Sole Trader',
                    value: 'Sole Trader'
                },
                {
                    label: 'Partnership',
                    value: 'Partnership'
                },
                {
                    label: 'Limited Liability Partnership',
                    value: 'Limited Liability Partnership'
                },
                {
                    label: 'Child Maintenance',
                    value: 'Child Maintenance'
                },
                {
                    label: 'Benefits',
                    value: 'Benefits'
                },
                {
                    label: 'Investment',
                    value: 'Investment'
                },
                {
                    label: 'Pension',
                    value: 'Pension'
                },
                {
                    label: 'Rental',
                    value: 'Rental'
                },
                {
                    label: 'Other',
                    value: 'Other'
                }
            ],
            incomeCommonSectorOptions: [
                {
                    label: 'Public Sector',
                    value: 'Public Sector'
                },
                {
                    label: 'Financials',
                    value: 'Financials'
                },
                {
                    label: 'Utilities',
                    value: 'Utilities'
                },
                {
                    label: 'Retail',
                    value: 'Retail'
                },
                {
                    label: 'Energy',
                    value: 'Energy'
                },
                {
                    label: 'Healthcare',
                    value: 'Healthcare'
                },
                {
                    label: 'Industrials',
                    value: 'Industrials'
                },
                {
                    label: 'Technology',
                    value: 'Technology'
                },
                {
                    label: 'Telecom',
                    value: 'Telecom'
                },
                {
                    label: 'Materials',
                    value: 'Materials'
                },
                {
                    label: 'Real Estate',
                    value: 'Real Estate'
                },
                {
                    label: 'Other',
                    value: 'Other'
                }
            ],
            incomeAccountantQualificationOptions: [
                {
                    label: 'ACA (Associate Chartered Accountant)',
                    value: 'ACA'
                },
                {
                    label: 'ACCA (Associate Chartered Certified Accountant)',
                    value: 'ACCA'
                },
                {
                    label:
                        'CIMA (Chartered Institute of Management Accountant)',
                    value: 'CIMA'
                }
            ],
            incomeEmployedContractTypeOptions: [
                {
                    label: 'Permanent',
                    value: 'Permanent'
                },
                {
                    label: 'Probationary',
                    value: 'Probationary'
                },
                {
                    label: 'Fixed Term',
                    value: 'Fixed Term'
                },
                {
                    label: 'Zero Hours',
                    value: 'Zero Hours'
                },
                {
                    label: 'Agency / Casual',
                    value: 'Agency / Casual'
                },
                {
                    label: 'Apprenticeship',
                    value: 'Apprenticeship'
                }
            ],
            incomeCommonAmountFrequencyOptions: [
                {
                    label: 'Weekly',
                    value: 'Weekly'
                },
                {
                    label: 'Fortnightly',
                    value: 'Fortnightly'
                },
                {
                    label: 'Monthly',
                    value: 'Monthly'
                },
                {
                    label: 'Yearly',
                    value: 'Yearly'
                }
            ],
            incomeBenefitsTypeOptions: [
                {
                    label: 'Adoption Allowance',
                    value: 'Adoption Allowance'
                },
                {
                    label: 'Armed Forces Compensation Scheme',
                    value: 'AFCS'
                },
                {
                    label: 'Armed Forces Independence Payment',
                    value: 'AFIP'
                },
                {
                    label: 'Attendance Allowance (AA)',
                    value: 'AA'
                },
                {
                    label: 'Carers Allowance (CA)',
                    value: 'CA'
                },
                {
                    label: 'Child Benefit',
                    value: 'Child Benefit'
                },
                {
                    label: 'Child Tax Credit',
                    value: 'CTC'
                },
                {
                    label: 'Disability living allowance (DLA)',
                    value: 'DLA'
                },
                {
                    label: 'Employment & Support Allowance',
                    value: 'ESA'
                },
                {
                    label: 'Incapacity Benefit',
                    value: 'Incapacity Benefit'
                },
                {
                    label: 'Industrial Injuries Disablement Benefit',
                    value: 'Industrial Injuries Disablement Benefit'
                },
                {
                    label: 'Personal Independence Payment (PIP)',
                    value: 'PIP'
                },
                {
                    label: 'Reduced Earning Allowance (REA)',
                    value: 'REA'
                },
                {
                    label: 'Universal Credit',
                    value: 'Universal Credit'
                },
                {
                    label: 'Widowed Parents Allowance',
                    value: 'Widowed Parents Allowance'
                },
                {
                    label: 'Working Tax Credit',
                    value: 'WTC'
                }
            ],
            incomeMaintenanceAgreementOptions: [
                {
                    label: 'Court Order',
                    value: 'Court Order'
                },
                {
                    label: 'Private Agreement',
                    value: 'Private Agreement'
                }
            ],
        }
    }
}
