<template>
  <QuestionBase :question="question" :errors="[...serverErrors, ...jsErrors]" :valid="data !== null">
    <InputSelect
        v-model="data"
        :options="incomeEmploymentOptions"
        :db="db"
        :args="args"
        :skipped="skipped"
        :jsErrors="jsErrors"
        :disabled="disabled"
        @skipped="skipped=$event"
        @serverErrors="serverErrors=$event"
        @jsErrors="jsErrors=$event"
    />
  </QuestionBase>
</template>

<script>
import { questionLoadHelpers } from "@/components/common/questionnaires/question/helpers/questionLoadHelpers";
import { incomeOptions } from "@/components/common/questionnaires/question/options/incomeOptions";

import QuestionBase from "@/components/common/questionnaires/question/QuestionBase";
import InputSelect from "@/components/common/questionnaires/question/inputs/InputSelect";

export default {
  name: 'PrimaryEmployment',
  mixins: [incomeOptions, questionLoadHelpers],
  components: {
    InputSelect,
    QuestionBase
  },
  props: {
    value: {
      type: String,
      required: false
    },
    saveLocation: {
      type: String,
      default: 'client_client'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    data: {
      set(value) {
        this.$emit('input', value)
      },
      get () {
        return this.value
      }
    }
  },
  data () {
    return {
      question: {
        title:  'What is your primary employment?',
        subTitle:  null,
        tip: null,
      },
      db: {
        saveLocation: this.saveLocation,
        saveField: 'profilePrimaryEmployment'
      },
      args: {
        taggable: false,
        label: null,
        placeholder: 'Please select..',
        skippable: false,
        required: true,
        inputClass: 'field-66'
      },
      serverErrors: [],
      jsErrors: [],
      skipped: false,
    }
  }
}
</script>
