<template>
  <QuestionBase :errors="[...errorsPost, ...serverErrors, ...jsErrors]"
                :question="question"
                :valid="!!data.length">
    <InputClients v-model="data"
                  :args="args"
                  :db="db"
                  :dbOther="dbOther"
                  :errors-post="errorsPost"
                  :js-errors="jsErrors"
                  :no-save="noSave"
                  :showOther="true"
                  :skipped="skipped"
                  :valueOther="other"
                  @inputOther="otherData=$event"
                  @jsErrors="jsErrors=$event"
                  @other="$emit('other')"
                  @serverErrors="serverErrors=$event"
                  @skipped="skipped=$event" />
  </QuestionBase>
</template>

<script>
import {questionLoadHelpers} from "../../helpers/questionLoadHelpers";

import QuestionBase from "../../QuestionBase";
import InputClients from "../../inputs/InputClients";

export default {
  name: 'Owners',
  mixins: [questionLoadHelpers],
  components: {
    InputClients,
    QuestionBase
  },
  props: {
    value: {
      type: Array,
      required: false
    },
    errorsPost: {
      type: Array,
      default: () => {
        return []
      }
    },
    noSave: {
      type: Boolean,
      default: false
    },
    objectId: {
      type: Number,
      required: false
    },
    other: {
      type: Boolean,
      required: false
    }
  },
  computed: {
    data: {
      set(value) {
        this.$emit('input',
            value)
      },
      get() {
        return this.value
      }
    },
    otherData: {
      set(value) {
        this.$emit('other',
            value)
      },
      get() {
        return this.other
      }
    }
  },
  data() {
    return {
      question: {
        title: 'Who is covered by the policy?',
        subTitle: 'If the policy is jointly owned by somebody who is not listed, please select yourself and  \'other\'.',
        tip: null
      },
      db: {
        saveLocation: 'client_policy',
        saveField: 'clients',
        objectId: this.objectId
      },
      dbOther: {
        saveLocation: 'client_policy',
        saveField: 'owner_other',
        objectId: this.objectId
      },
      args: {
        skippable: false,
        required: false,
      },
      serverErrors: [],
      jsErrors: [],
      skipped: false,
    }
  }
}
</script>