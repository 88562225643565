<template>
  <div class="fieldset">
    <h3 class="fieldset-heading">Address</h3>
    <AddressCurrent v-model="form.current_address" :form="form" />

    <MovedInDate v-model="form.profileAddressMovedIn" />
    <Occupancy v-model="form.profileAddressOccupancy" />
    <div
        v-if="showLandLordNameQuestion"
        class="question-box with-arrow"
    >
      <LandLordName v-model="form.profileAddressLandlordName" />
    </div>

    <CorrespondenceYN v-model="form.profileAddressCorrespondenceYN" />
    <div
        v-if="form.profileAddressCorrespondenceYN"
        class="question-box with-arrow"
    >
      <CorrespondenceDetails v-model="form.profileAddressCorrespondenceDetails" />
    </div>

    <AddressHistory v-if="showAddressHistory" v-model="form.previous_address" :years-required="addressHistoryYearsRequired" />

  </div>
</template>

<script>
import {fieldsetHelpers} from "../fieldsetHelpers";

import MovedInDate from "../../question/questions/clientProfile/address/MovedInDate";
import Occupancy from "../../question/questions/clientProfile/address/Occupancy";
import LandLordName from "../../question/questions/clientProfile/address/LandLordName";
import CorrespondenceYN from "../../question/questions/clientProfile/address/CorrespondenceYN";
import CorrespondenceDetails from "../../question/questions/clientProfile/address/CorrespondenceDetails";
import AddressHistory from "../../question/questions/clientProfile/address/AddressHistory";
import AddressCurrent from "../../question/questions/clientProfile/address/AddressCurrent";

export default {
  name: 'AddressFull',
  mixins: [fieldsetHelpers],
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  components: {
    AddressCurrent,
    AddressHistory,
    CorrespondenceDetails,
    CorrespondenceYN,
    LandLordName,
    Occupancy,
    MovedInDate,
  },
  data() {
    return {
      label: 'Address', // used for steps progress
      addressHistoryYearsRequired: 3,
      videoUrl: 'https://dm-kernel-public.s3.eu-west-2.amazonaws.com/static/video/kernel_help/mortgage_address_history.mp4'
    }
  },
  computed: {
    form: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    },
    showLandLordNameQuestion () {
      return ['Renting Private', 'Renting Local Authority'].includes(this.form.profileAddressOccupancy)
    },
    showAddressHistory () {
      let now = new Date()
      return this.form.profileAddressMovedIn && now.setFullYear(now.getFullYear() - this.addressHistoryYearsRequired) < new Date(this.form.profileAddressMovedIn)
    }
  }
};
</script>
