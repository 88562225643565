<template>
  <QuestionBase :id="db.saveField"
                :errors="[...jsErrors]"
                :question="question"

                :valid="!!data.length">

    <transition name="fade">
      <div class="accordion sub-items">

        <ObjectCard v-for="(income, index) in data"
                    :id="'income'+index"
                    :key="'income' + income.id"
                    :ref="'income'+index"
                    v-model="data[index]"
                    :title="income.incomeType"
                    type="income"
                    :loading="loading"
                    @delete="deleteObject(income.id)"
                    @save="saveObject(income, 'income'+index)">
          <IncomeFull
              :key="'income-form' + income.id"
              v-model="data[index]"
              :errors-post="errorsPost"
              :disabledIncomeInput="disabledIncomeInput"
              :disabledIncomeClients="disabledIncomeClients"
              :objectId="income.id"/>
        </ObjectCard>

        <ObjectCard v-if="form"
                    ref="income-add"
                    v-model="form"
                    title="New Income"
                    type="income-add"
                    :loading="loading"
                    @delete="form=null"
                    @save="saveObject(form, db.saveField)">
          <IncomeFull
              v-model="form"
              :errors-post="errorsPost"
              :disabledIncomeInput="disabledIncomeInput"
              :disabledIncomeClients="disabledIncomeClients"
              :noSave="true"/>
        </ObjectCard>

        <b-button
            v-if="showAddButton && !form"
            class="btn-question w-100"
            @click="add">
          <i class="i-Money-Bag text-25 font-weight-800 mr-2"> </i>
          {{ addButtonText }}
        </b-button>

      </div>
    </transition>
  </QuestionBase>

</template>

<script>
import {objectSaveHelpers} from "../../objects/objectSaveHelpers";
import {questionLoadHelpers} from "../../../helpers/questionLoadHelpers";
import {blankForms} from "../../../helpers/blankForms";
import {clientHelpers} from "@/mixins/clientHelpers";
import {incomeOptions} from "../../../options/incomeOptions";

import QuestionBase from "../../../QuestionBase";
import ObjectCard from "../../objects/ObjectCard";
import IncomeFull from "../../../../fieldsets/sub/income/IncomeFull";

export default {
  name: 'Income',
  components: {
    IncomeFull,
    ObjectCard,
    QuestionBase
  },
  mixins: [
    objectSaveHelpers,
    questionLoadHelpers,
    blankForms,
    clientHelpers,
    incomeOptions
  ],
  props: {
    value: {
      type: Array,
      required: false
    },
    category: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: false
    },
    subTitle: {
      type: String,
      required: false
    },
    showAddButton: {
      type: Boolean,
      default: false
    },
    addButtonText: {
      type: String,
      default: 'Add Income'
    },
    initialIncomeType: {
      type: String,
      required: false
    },
    initialIncomeClient: {
      type: Number,
      required: false
    },
    disabledIncomeInput: {
      type: Boolean,
      default: false
    },
    disabledIncomeClients: {
      type: Boolean,
      default: false
    },
    saveField: {
      type: String,
      required: true
    }
  },
  watch: {
    initialIncomeType: {
      deep: false,
      immediate: false,
      handler(value) {
        if (this.form) {
          this.form.incomeType = value
        }
      }
    },
    initialIncomeClient: {
      deep: false,
      immediate: false,
      handler(value) {
        this.form.clients = [value]
      }
    }
  },
  data() {
    return {
      incomeMainInput: null,
      question: {
        title: this.title,
        subTitle: this.subTitle,
        tip: null,
      },
      db: {
        saveLocation: 'client_income',
        saveField: this.saveField,
        deleteLocation: 'client_income',
        loadLocation: 'client_income',
        syncMultipleObjectOnSave: false,
        fetchObjectsOnSave: false
      },
      args: {
        skippable: false,
        required: true,
        customErrorMessageNewOpenForm: "Save income before proceeding, by clicking 'confirm'"
      },
      serverErrors: [],
      errorsPost: {},
      jsErrors: [],
      skipped: false,
      form: null,
      form_multi: null,
      errors: {}
    }
  },
  computed: {
    data: {
      set(value) {
        this.$emit('input',
            value)
      },
      get() {
        return this.value
      }
    },
  },
  methods: {
    setForm(incomeType) {
      console.log('incomeType', incomeType);
    },
    add() {
      console.log('add income', this.initialIncomeType)
      this.form = null
      this.form = Object.assign({}, this.incomeBlankForm)
      this.form.incomeType = this.initialIncomeType
      this.form.clients = [this.initialIncomeClient]
      this.form.category = this.category
    },
  }
}
</script>
