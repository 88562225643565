<template>
  <div class="fieldset">
    <h3 class="fieldset-heading">Policies</h3>
    <PoliciesMain ref="policiesMain" type="mortgage" v-model="form.policies_main" @refresh="refreshPolicies"/>
  </div>
</template>

<script>
import {fieldsetHelpers} from "../fieldsetHelpers";
import PoliciesMain from "../../question/questions/clientProfile/policies/PoliciesMain";

export default {
  name: 'PoliciesMortgage',
  mixins: [fieldsetHelpers],
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  components: {
    PoliciesMain,
  },
  data() {
    return {
      label: 'Policies', // used for steps progress
      videoUrl: 'https://dm-kernel-public.s3.eu-west-2.amazonaws.com/static/video/kernel_help/mortgage_policies.mp4'
    }
  },
  computed: {
    form: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    }
  },
  methods: {
    refreshPolicies() {
      if ('policiesMain' in this.$refs) this.$refs.policiesMain.get()
      if ('policiesOther' in this.$refs) this.$refs.policiesOther.get()
    }
  }
};
</script>
