export const countryOptions = {
    data() {
        return {
            countryOptions: [
                {
                    label: 'England',
                    value: 'England'
                },
                {
                    label: 'Scotland',
                    value: 'Scotland'
                },
                {
                    label: 'Wales',
                    value: 'Wales'
                },
                {
                    label: 'Northern Ireland',
                    value: 'Northern Ireland'
                },
                {
                    label: 'Afghanistan',
                    value: 'Afghanistan'
                },
                {
                    label: 'Albania',
                    value: 'Albania'
                },
                {
                    label: 'Algeria',
                    value: 'Algeria'
                },
                {
                    label: 'Andorra',
                    value: 'Andorra'
                },
                {
                    label: 'Angola',
                    value: 'Angola'
                },
                {
                    label: 'Anguilla',
                    value: 'Anguilla'
                },
                {
                    label: 'Antigua & Barbuda',
                    value: 'Antigua & Barbuda'
                },
                {
                    label: 'Argentina',
                    value: 'Argentina'
                },
                {
                    label: 'Armenia',
                    value: 'Armenia'
                },
                {
                    label: 'Australia',
                    value: 'Australia'
                },
                {
                    label: 'Austria',
                    value: 'Austria'
                },
                {
                    label: 'Azerbaijan',
                    value: 'Azerbaijan'
                },
                {
                    label: 'Bahamas',
                    value: 'Bahamas'
                },
                {
                    label: 'Bahrain',
                    value: 'Bahrain'
                },
                {
                    label: 'Bangladesh',
                    value: 'Bangladesh'
                },
                {
                    label: 'Barbados',
                    value: 'Barbados'
                },
                {
                    label: 'Belarus',
                    value: 'Belarus'
                },
                {
                    label: 'Belgium',
                    value: 'Belgium'
                },
                {
                    label: 'Belize',
                    value: 'Belize'
                },
                {
                    label: 'Benin',
                    value: 'Benin'
                },
                {
                    label: 'Bermuda',
                    value: 'Bermuda'
                },
                {
                    label: 'Bhutan',
                    value: 'Bhutan'
                },
                {
                    label: 'Bolivia',
                    value: 'Bolivia'
                },
                {
                    label: 'Bosnia & Herzegovina',
                    value: 'Bosnia & Herzegovina'
                },
                {
                    label: 'Botswana',
                    value: 'Botswana'
                },
                {
                    label: 'Brazil',
                    value: 'Brazil'
                },
                {
                    label: 'Brunei Darussalam',
                    value: 'Brunei Darussalam'
                },
                {
                    label: 'Bulgaria',
                    value: 'Bulgaria'
                },
                {
                    label: 'Burkina Faso',
                    value: 'Burkina Faso'
                },
                {
                    label: 'Burundi',
                    value: 'Burundi'
                },
                {
                    label: 'Cambodia',
                    value: 'Cambodia'
                },
                {
                    label: 'Cameroon',
                    value: 'Cameroon'
                },
                {
                    label: 'Canada',
                    value: 'Canada'
                },
                {
                    label: 'Cape Verde',
                    value: 'Cape Verde'
                },
                {
                    label: 'Cayman Islands',
                    value: 'Cayman Islands'
                },
                {
                    label: 'Central African Republic',
                    value: 'Central African Republic'
                },
                {
                    label: 'Chad',
                    value: 'Chad'
                },
                {
                    label: 'Chile',
                    value: 'Chile'
                },
                {
                    label: 'China',
                    value: 'China'
                },
                {
                    label: 'China - Hong Kong / Macau',
                    value: 'China - Hong Kong / Macau'
                },
                {
                    label: 'Colombia',
                    value: 'Colombia'
                },
                {
                    label: 'Comoros',
                    value: 'Comoros'
                },
                {
                    label: 'Congo',
                    value: 'Congo'
                },
                {
                    label: 'Congo, Democratic Republic of (DRC)',
                    value: 'Congo, Democratic Republic of (DRC)'
                },
                {
                    label: 'Costa Rica',
                    value: 'Costa Rica'
                },
                {
                    label: 'Croatia',
                    value: 'Croatia'
                },
                {
                    label: 'Cuba',
                    value: 'Cuba'
                },
                {
                    label: 'Cyprus',
                    value: 'Cyprus'
                },
                {
                    label: 'Czech Republic',
                    value: 'Czech Republic'
                },
                {
                    label: 'Denmark',
                    value: 'Denmark'
                },
                {
                    label: 'Djibouti',
                    value: 'Djibouti'
                },
                {
                    label: 'Dominica',
                    value: 'Dominica'
                },
                {
                    label: 'Dominican Republic',
                    value: 'Dominican Republic'
                },
                {
                    label: 'Ecuador',
                    value: 'Ecuador'
                },
                {
                    label: 'Egypt',
                    value: 'Egypt'
                },
                {
                    label: 'El Salvador',
                    value: 'El Salvador'
                },

                {
                    label: 'Equatorial Guinea',
                    value: 'Equatorial Guinea'
                },
                {
                    label: 'Eritrea',
                    value: 'Eritrea'
                },
                {
                    label: 'Estonia',
                    value: 'Estonia'
                },
                {
                    label: 'Eswatini',
                    value: 'Eswatini'
                },
                {
                    label: 'Ethiopia',
                    value: 'Ethiopia'
                },
                {
                    label: 'Fiji',
                    value: 'Fiji'
                },
                {
                    label: 'Finland',
                    value: 'Finland'
                },
                {
                    label: 'France',
                    value: 'France'
                },
                {
                    label: 'French Guiana',
                    value: 'French Guiana'
                },
                {
                    label: 'Gabon',
                    value: 'Gabon'
                },
                {
                    label: 'Gambia, Republic of The',
                    value: 'Gambia, Republic of The'
                },
                {
                    label: 'Georgia',
                    value: 'Georgia'
                },
                {
                    label: 'Germany',
                    value: 'Germany'
                },
                {
                    label: 'Ghana',
                    value: 'Ghana'
                },
                {
                    label: 'Greece',
                    value: 'Greece'
                },
                {
                    label: 'Grenada',
                    value: 'Grenada'
                },
                {
                    label: 'Guadeloupe',
                    value: 'Guadeloupe'
                },
                {
                    label: 'Guatemala',
                    value: 'Guatemala'
                },
                {
                    label: 'Guinea',
                    value: 'Guinea'
                },
                {
                    label: 'Guinea-Bissau',
                    value: 'Guinea-Bissau'
                },
                {
                    label: 'Guyana',
                    value: 'Guyana'
                },
                {
                    label: 'Haiti',
                    value: 'Haiti'
                },
                {
                    label: 'Honduras',
                    value: 'Honduras'
                },
                {
                    label: 'Hungary',
                    value: 'Hungary'
                },
                {
                    label: 'Iceland',
                    value: 'Iceland'
                },
                {
                    label: 'India',
                    value: 'India'
                },
                {
                    label: 'Indonesia',
                    value: 'Indonesia'
                },
                {
                    label: 'Iran',
                    value: 'Iran'
                },
                {
                    label: 'Iraq',
                    value: 'Iraq'
                },
                {
                    label: 'Israel and the Occupied Territories',
                    value: 'Israel and the Occupied Territories'
                },
                {
                    label: 'Italy',
                    value: 'Italy'
                },
                {
                    label: 'Ivory Coast',
                    value: 'Ivory Coast'
                },
                {
                    label: 'Jamaica',
                    value: 'Jamaica'
                },
                {
                    label: 'Japan',
                    value: 'Japan'
                },
                {
                    label: 'Jordan',
                    value: 'Jordan'
                },
                {
                    label: 'Kazakhstan',
                    value: 'Kazakhstan'
                },
                {
                    label: 'Kenya',
                    value: 'Kenya'
                },
                {
                    label: 'Korea, Democratic Republic of (North Korea)',
                    value: 'Korea, Democratic Republic of (North Korea)'
                },
                {
                    label: 'Korea, Republic of (South Korea)',
                    value: 'Korea, Republic of (South Korea)'
                },
                {
                    label: 'Kosovo',
                    value: 'Kosovo'
                },
                {
                    label: 'Kuwait',
                    value: 'Kuwait'
                },
                {
                    label: 'Kyrgyz Republic (Kyrgyzstan)',
                    value: 'Kyrgyz Republic (Kyrgyzstan)'
                },
                {
                    label: 'Laos',
                    value: 'Laos'
                },
                {
                    label: 'Latvia',
                    value: 'Latvia'
                },
                {
                    label: 'Lebanon',
                    value: 'Lebanon'
                },
                {
                    label: 'Lesotho',
                    value: 'Lesotho'
                },
                {
                    label: 'Liberia',
                    value: 'Liberia'
                },
                {
                    label: 'Libya',
                    value: 'Libya'
                },
                {
                    label: 'Liechtenstein',
                    value: 'Liechtenstein'
                },
                {
                    label: 'Lithuania',
                    value: 'Lithuania'
                },
                {
                    label: 'Luxembourg',
                    value: 'Luxembourg'
                },
                {
                    label: 'Madagascar',
                    value: 'Madagascar'
                },
                {
                    label: 'Malawi',
                    value: 'Malawi'
                },
                {
                    label: 'Malaysia',
                    value: 'Malaysia'
                },
                {
                    label: 'Maldives',
                    value: 'Maldives'
                },
                {
                    label: 'Mali',
                    value: 'Mali'
                },
                {
                    label: 'Malta',
                    value: 'Malta'
                },
                {
                    label: 'Martinique',
                    value: 'Martinique'
                },
                {
                    label: 'Mauritania',
                    value: 'Mauritania'
                },
                {
                    label: 'Mauritius',
                    value: 'Mauritius'
                },
                {
                    label: 'Mayotte',
                    value: 'Mayotte'
                },
                {
                    label: 'Mexico',
                    value: 'Mexico'
                },
                {
                    label: 'Moldova, Republic of',
                    value: 'Moldova, Republic of'
                },
                {
                    label: 'Monaco',
                    value: 'Monaco'
                },
                {
                    label: 'Mongolia',
                    value: 'Mongolia'
                },
                {
                    label: 'Montenegro',
                    value: 'Montenegro'
                },
                {
                    label: 'Montserrat',
                    value: 'Montserrat'
                },
                {
                    label: 'Morocco',
                    value: 'Morocco'
                },
                {
                    label: 'Mozambique',
                    value: 'Mozambique'
                },
                {
                    label: 'Myanmar/Burma',
                    value: 'Myanmar/Burma'
                },
                {
                    label: 'Namibia',
                    value: 'Namibia'
                },
                {
                    label: 'Nepal',
                    value: 'Nepal'
                },
                {
                    label: 'Netherlands',
                    value: 'Netherlands'
                },
                {
                    label: 'New Zealand',
                    value: 'New Zealand'
                },
                {
                    label: 'Nicaragua',
                    value: 'Nicaragua'
                },
                {
                    label: 'Niger',
                    value: 'Niger'
                },
                {
                    label: 'Nigeria',
                    value: 'Nigeria'
                },
                {
                    label: 'North Macedonia, Republic of',
                    value: 'North Macedonia, Republic of'
                },
                {
                    label: 'Norway',
                    value: 'Norway'
                },
                {
                    label: 'Republic of Ireland',
                    value: 'Republic of Ireland'
                },
                {
                    label: 'Oman',
                    value: 'Oman'
                },
                {
                    label: 'Pacific Islands',
                    value: 'Pacific Islands'
                },
                {
                    label: 'Pakistan',
                    value: 'Pakistan'
                },
                {
                    label: 'Panama',
                    value: 'Panama'
                },
                {
                    label: 'Papua New Guinea',
                    value: 'Papua New Guinea'
                },
                {
                    label: 'Paraguay',
                    value: 'Paraguay'
                },
                {
                    label: 'Peru',
                    value: 'Peru'
                },
                {
                    label: 'Philippines',
                    value: 'Philippines'
                },
                {
                    label: 'Poland',
                    value: 'Poland'
                },
                {
                    label: 'Portugal',
                    value: 'Portugal'
                },
                {
                    label: 'Puerto Rico',
                    value: 'Puerto Rico'
                },
                {
                    label: 'Qatar',
                    value: 'Qatar'
                },
                {
                    label: 'Reunion',
                    value: 'Reunion'
                },
                {
                    label: 'Romania',
                    value: 'Romania'
                },
                {
                    label: 'Russian Federation',
                    value: 'Russian Federation'
                },
                {
                    label: 'Rwanda',
                    value: 'Rwanda'
                },
                {
                    label: 'Saint Kitts and Nevis',
                    value: 'Saint Kitts and Nevis'
                },
                {
                    label: 'Saint Lucia',
                    value: 'Saint Lucia'
                },
                {
                    label: 'Saint Vincent and the Grenadines',
                    value: 'Saint Vincent and the Grenadines'
                },
                {
                    label: 'Samoa',
                    value: 'Samoa'
                },
                {
                    label: 'Sao Tome and Principe',
                    value: 'Sao Tome and Principe'
                },
                {
                    label: 'Saudi Arabia',
                    value: 'Saudi Arabia'
                },

                {
                    label: 'Senegal',
                    value: 'Senegal'
                },
                {
                    label: 'Serbia',
                    value: 'Serbia'
                },
                {
                    label: 'Seychelles',
                    value: 'Seychelles'
                },
                {
                    label: 'Sierra Leone',
                    value: 'Sierra Leone'
                },
                {
                    label: 'Singapore',
                    value: 'Singapore'
                },
                {
                    label: 'Slovak Republic (Slovakia)',
                    value: 'Slovak Republic (Slovakia)'
                },
                {
                    label: 'Slovenia',
                    value: 'Slovenia'
                },
                {
                    label: 'Solomon Islands',
                    value: 'Solomon Islands'
                },
                {
                    label: 'Somalia',
                    value: 'Somalia'
                },
                {
                    label: 'South Africa',
                    value: 'South Africa'
                },
                {
                    label: 'South Sudan',
                    value: 'South Sudan'
                },
                {
                    label: 'Spain',
                    value: 'Spain'
                },
                {
                    label: 'Sri Lanka',
                    value: 'Sri Lanka'
                },
                {
                    label: 'Sudan',
                    value: 'Sudan'
                },
                {
                    label: 'Suriname',
                    value: 'Suriname'
                },
                {
                    label: 'Sweden',
                    value: 'Sweden'
                },
                {
                    label: 'Switzerland',
                    value: 'Switzerland'
                },
                {
                    label: 'Syria',
                    value: 'Syria'
                },
                {
                    label: 'Tajikistan',
                    value: 'Tajikistan'
                },
                {
                    label: 'Tanzania',
                    value: 'Tanzania'
                },
                {
                    label: 'Thailand',
                    value: 'Thailand'
                },
                {
                    label: 'Timor Leste',
                    value: 'Timor Leste'
                },
                {
                    label: 'Togo',
                    value: 'Togo'
                },
                {
                    label: 'Trinidad & Tobago',
                    value: 'Trinidad & Tobago'
                },
                {
                    label: 'Tunisia',
                    value: 'Tunisia'
                },
                {
                    label: 'Turkey',
                    value: 'Turkey'
                },
                {
                    label: 'Turkmenistan',
                    value: 'Turkmenistan'
                },
                {
                    label: 'Turks & Caicos Islands',
                    value: 'Turks & Caicos Islands'
                },
                {
                    label: 'Uganda',
                    value: 'Uganda'
                },
                {
                    label: 'Ukraine',
                    value: 'Ukraine'
                },
                {
                    label: 'United Arab Emirates',
                    value: 'United Arab Emirates'
                },
                {
                    label: 'United States of America (USA)',
                    value: 'United States of America (USA)'
                },
                {
                    label: 'Uruguay',
                    value: 'Uruguay'
                },
                {
                    label: 'Uzbekistan',
                    value: 'Uzbekistan'
                },
                {
                    label: 'Venezuela',
                    value: 'Venezuela'
                },
                {
                    label: 'Vietnam',
                    value: 'Vietnam'
                },
                {
                    label: 'Virgin Islands (UK)',
                    value: 'Virgin Islands (UK)'
                },
                {
                    label: 'Virgin Islands (US)',
                    value: 'Virgin Islands (US)'
                },

                {
                    label: 'Yemen',
                    value: 'Yemen'
                },
                {
                    label: 'Zambia',
                    value: 'Zambia'
                },
                {
                    label: 'Zimbabwe',
                    value: 'Zimbabwe'
                }
            ],
            countryGBOptions: [
                {
                    label: 'England',
                    value: 'England'
                },
                {
                    label: 'Scotland',
                    value: 'Scotland'
                },
                {
                    label: 'Wales',
                    value: 'Wales'
                },
                {
                    label: 'Northern Ireland',
                    value: 'Northern Ireland'
                }
            ],
            nationalityOptions: [
                {
                    label: 'British',
                    value: 'British'
                },
                {
                    label: 'Afghan',
                    value: 'Afghan'
                },
                {
                    label: 'Albanian',
                    value: 'Albanian'
                },
                {
                    label: 'Algerian',
                    value: 'Algerian'
                },
                {
                    label: 'Andorran',
                    value: 'Andorran'
                },
                {
                    label: 'Angolan',
                    value: 'Angolan'
                },
                {
                    label: 'Antiguan',
                    value: 'Antiguan'
                },
                {
                    label: 'Argentine',
                    value: 'Argentine'
                },
                {
                    label: 'Armenian',
                    value: 'Armenian'
                },
                {
                    label: 'Australian',
                    value: 'Australian'
                },
                {
                    label: 'Austrian',
                    value: 'Austrian'
                },
                {
                    label: 'Azerbaijani',
                    value: 'Azerbaijani'
                },
                {
                    label: 'Bahamian',
                    value: 'Bahamian'
                },
                {
                    label: 'Bahraini',
                    value: 'Bahraini'
                },
                {
                    label: 'Bengali',
                    value: 'Bengali'
                },
                {
                    label: 'Barbadian',
                    value: 'Barbadian'
                },
                {
                    label: 'Belarusian',
                    value: 'Belarusian'
                },
                {
                    label: 'Belgian',
                    value: 'Belgian'
                },
                {
                    label: 'Belizean',
                    value: 'Belizean'
                },
                {
                    label: 'Beninese',
                    value: 'Beninese'
                },
                {
                    label: 'Bhutanese',
                    value: 'Bhutanese'
                },
                {
                    label: 'Bolivian',
                    value: 'Bolivian'
                },
                {
                    label: 'Bosnian / Herzegovinian',
                    value: 'Bosnian / Herzegovinian'
                },
                {
                    label: 'Motswana / Botswanan',
                    value: 'Motswana / Botswanan'
                },
                {
                    label: 'Brazilian',
                    value: 'Brazilian'
                },
                {
                    label: 'Bruneian',
                    value: 'Bruneian'
                },
                {
                    label: 'Bulgarian',
                    value: 'Bulgarian'
                },
                {
                    label: 'Burkinabé',
                    value: 'Burkinabé'
                },
                {
                    label: 'Burmese',
                    value: 'Burmese'
                },
                {
                    label: 'Burundian',
                    value: 'Burundian'
                },
                {
                    label: 'Cabo Verdean',
                    value: 'Cabo Verdean'
                },
                {
                    label: 'Cambodian',
                    value: 'Cambodian'
                },
                {
                    label: 'Cameroonian',
                    value: 'Cameroonian'
                },
                {
                    label: 'Canadian',
                    value: 'Canadian'
                },
                {
                    label: 'Central African',
                    value: 'Central African'
                },
                {
                    label: 'Chadian',
                    value: 'Chadian'
                },
                {
                    label: 'Chilean',
                    value: 'Chilean'
                },
                {
                    label: 'Chinese',
                    value: 'Chinese'
                },
                {
                    label: 'Colombian',
                    value: 'Colombian'
                },
                {
                    label: 'Comoran',
                    value: 'Comoran'
                },
                {
                    label: 'Congolese',
                    value: 'Congolese'
                },
                {
                    label: 'Costa Rican',
                    value: 'Costa Rican'
                },
                {
                    label: 'Croatian',
                    value: 'Croatian'
                },
                {
                    label: 'Cuban',
                    value: 'Cuban'
                },
                {
                    label: 'Cypriot',
                    value: 'Cypriot'
                },
                {
                    label: 'Czech',
                    value: 'Czech'
                },
                {
                    label: 'Danish',
                    value: 'Danish'
                },
                {
                    label: 'Djiboutian',
                    value: 'Djiboutian'
                },
                {
                    label: 'Dominican',
                    value: 'Dominican'
                },
                {
                    label: 'Timorese',
                    value: 'Timorese'
                },
                {
                    label: 'Ecuadorian',
                    value: 'Ecuadorian'
                },
                {
                    label: 'Egyptian',
                    value: 'Egyptian'
                },
                {
                    label: 'Salvadoran',
                    value: 'Salvadoran'
                },
                {
                    label: 'Equatorial Guinean',
                    value: 'Equatorial Guinean'
                },
                {
                    label: 'Eritrean',
                    value: 'Eritrean'
                },
                {
                    label: 'Estonian',
                    value: 'Estonian'
                },
                {
                    label: 'Ethiopian',
                    value: 'Ethiopian'
                },
                {
                    label: 'Fijian',
                    value: 'Fijian'
                },
                {
                    label: 'Finnish',
                    value: 'Finnish'
                },
                {
                    label: 'French',
                    value: 'French'
                },
                {
                    label: 'Gabonese',
                    value: 'Gabonese'
                },
                {
                    label: 'Gambian',
                    value: 'Gambian'
                },
                {
                    label: 'Georgian',
                    value: 'Georgian'
                },
                {
                    label: 'German',
                    value: 'German'
                },
                {
                    label: 'Ghanaian',
                    value: 'Ghanaian'
                },
                {
                    label: 'Gibraltar',
                    value: 'Gibraltar'
                },
                {
                    label: 'Greek',
                    value: 'Greek'
                },
                {
                    label: 'Grenadian',
                    value: 'Grenadian'
                },
                {
                    label: 'Guatemalan',
                    value: 'Guatemalan'
                },
                {
                    label: 'Guinean',
                    value: 'Guinean'
                },
                {
                    label: 'Bissau-Guinean',
                    value: 'Bissau-Guinean'
                },
                {
                    label: 'Guyanese',
                    value: 'Guyanese'
                },
                {
                    label: 'Haitian',
                    value: 'Haitian'
                },
                {
                    label: 'Honduran',
                    value: 'Honduran'
                },
                {
                    label: 'Hungarian',
                    value: 'Hungarian'
                },
                {
                    label: 'Icelandic',
                    value: 'Icelandic'
                },
                {
                    label: 'Indian',
                    value: 'Indian'
                },
                {
                    label: 'Indonesian',
                    value: 'Indonesian'
                },
                {
                    label: 'Iranian',
                    value: 'Iranian'
                },
                {
                    label: 'Iraqi',
                    value: 'Iraqi'
                },
                {
                    label: 'Irish',
                    value: 'Irish'
                },
                {
                    label: 'Israeli',
                    value: 'Israeli'
                },
                {
                    label: 'Italian',
                    value: 'Italian'
                },
                {
                    label: 'Ivorian',
                    value: 'Ivorian'
                },
                {
                    label: 'Jamaican',
                    value: 'Jamaican'
                },
                {
                    label: 'Japanese',
                    value: 'Japanese'
                },
                {
                    label: 'Jordanian',
                    value: 'Jordanian'
                },
                {
                    label: 'Kazakhstani',
                    value: 'Kazakhstani'
                },
                {
                    label: 'Kenyan',
                    value: 'Kenyan'
                },
                {
                    label: 'I-Kiribati',
                    value: 'I-Kiribati'
                },
                {
                    label: 'North Korean',
                    value: 'North Korean'
                },
                {
                    label: 'South Korean',
                    value: 'South Korean'
                },
                {
                    label: 'Kuwaiti',
                    value: 'Kuwaiti'
                },
                {
                    label: 'Kyrgyzstani',
                    value: 'Kyrgyzstani'
                },
                {
                    label: 'Lao',
                    value: 'Lao'
                },
                {
                    label: 'Latvian',
                    value: 'Latvian'
                },
                {
                    label: 'Lebanese',
                    value: 'Lebanese'
                },
                {
                    label: 'Basotho',
                    value: 'Basotho'
                },
                {
                    label: 'Liberian',
                    value: 'Liberian'
                },
                {
                    label: 'Libyan',
                    value: 'Libyan'
                },
                {
                    label: 'Liechtensteiner',
                    value: 'Liechtensteiner'
                },
                {
                    label: 'Lithuanian',
                    value: 'Lithuanian'
                },
                {
                    label: 'Luxembourg',
                    value: 'Luxembourg'
                },
                {
                    label: 'Macedonian',
                    value: 'Macedonian'
                },
                {
                    label: 'Malagasy',
                    value: 'Malagasy'
                },
                {
                    label: 'Malawian',
                    value: 'Malawian'
                },
                {
                    label: 'Malaysian',
                    value: 'Malaysian'
                },
                {
                    label: 'Maldivian',
                    value: 'Maldivian'
                },
                {
                    label: 'Malian',
                    value: 'Malian'
                },
                {
                    label: 'Maltese',
                    value: 'Maltese'
                },
                {
                    label: 'Marshallese',
                    value: 'Marshallese'
                },
                {
                    label: 'Martiniquais',
                    value: 'Martiniquais'
                },
                {
                    label: 'Mauritanian',
                    value: 'Mauritanian'
                },
                {
                    label: 'Mauritian',
                    value: 'Mauritian'
                },
                {
                    label: 'Mexican',
                    value: 'Mexican'
                },
                {
                    label: 'Micronesian',
                    value: 'Micronesian'
                },
                {
                    label: 'Moldovan',
                    value: 'Moldovan'
                },
                {
                    label: 'Monégasque',
                    value: 'Monégasque'
                },
                {
                    label: 'Mongolian',
                    value: 'Mongolian'
                },
                {
                    label: 'Montenegrin',
                    value: 'Montenegrin'
                },
                {
                    label: 'Moroccan',
                    value: 'Moroccan'
                },
                {
                    label: 'Mozambican',
                    value: 'Mozambican'
                },
                {
                    label: 'Namibian',
                    value: 'Namibian'
                },
                {
                    label: 'Nauruan',
                    value: 'Nauruan'
                },
                {
                    label: 'Nepali',
                    value: 'Nepali'
                },
                {
                    label: 'Dutch',
                    value: 'Dutch'
                },
                {
                    label: 'New Zealand',
                    value: 'New Zealand'
                },
                {
                    label: 'Nicaraguan',
                    value: 'Nicaraguan'
                },
                {
                    label: 'Nigerien',
                    value: 'Nigerien'
                },
                {
                    label: 'Nigerian',
                    value: 'Nigerian'
                },
                {
                    label: 'Northern Marianan',
                    value: 'Northern Marianan'
                },
                {
                    label: 'Norwegian',
                    value: 'Norwegian'
                },
                {
                    label: 'Omani',
                    value: 'Omani'
                },
                {
                    label: 'Pakistani',
                    value: 'Pakistani'
                },
                {
                    label: 'Palauan',
                    value: 'Palauan'
                },
                {
                    label: 'Palestinian',
                    value: 'Palestinian'
                },
                {
                    label: 'Panamanian',
                    value: 'Panamanian'
                },
                {
                    label: 'Papua New Guinean',
                    value: 'Papua New Guinean'
                },
                {
                    label: 'Paraguayan',
                    value: 'Paraguayan'
                },
                {
                    label: 'Peruvian',
                    value: 'Peruvian'
                },
                {
                    label: 'Filipino',
                    value: 'Filipino'
                },
                {
                    label: 'Polish',
                    value: 'Polish'
                },
                {
                    label: 'Portuguese',
                    value: 'Portuguese'
                },
                {
                    label: 'Puerto Rican',
                    value: 'Puerto Rican'
                },
                {
                    label: 'Qatari',
                    value: 'Qatari'
                },
                {
                    label: 'Romanian',
                    value: 'Romanian'
                },
                {
                    label: 'Russian',
                    value: 'Russian'
                },
                {
                    label: 'Rwandan',
                    value: 'Rwandan'
                },
                {
                    label: 'Kittitian',
                    value: 'Kittitian'
                },
                {
                    label: 'Saint Lucian',
                    value: 'Saint Lucian'
                },
                {
                    label: 'Saint Vincentian',
                    value: 'Saint Vincentian'
                },
                {
                    label: 'Samoan',
                    value: 'Samoan'
                },
                {
                    label: 'Sammarinese',
                    value: 'Sammarinese'
                },
                {
                    label: 'São Toméan',
                    value: 'São Toméan'
                },
                {
                    label: 'Saudi',
                    value: 'Saudi'
                },
                {
                    label: 'Senegalese',
                    value: 'Senegalese'
                },
                {
                    label: 'Serbian',
                    value: 'Serbian'
                },
                {
                    label: 'Seychellois',
                    value: 'Seychellois'
                },
                {
                    label: 'Sierra Leonean',
                    value: 'Sierra Leonean'
                },
                {
                    label: 'Singapore',
                    value: 'Singapore'
                },
                {
                    label: 'Slovak',
                    value: 'Slovak'
                },
                {
                    label: 'Slovenian, Slovene',
                    value: 'Slovenian, Slovene'
                },
                {
                    label: 'Solomon Island',
                    value: 'Solomon Island'
                },
                {
                    label: 'Somali',
                    value: 'Somali'
                },
                {
                    label: 'South African',
                    value: 'South African'
                },
                {
                    label: 'South Sudanese',
                    value: 'South Sudanese'
                },
                {
                    label: 'Spanish',
                    value: 'Spanish'
                },
                {
                    label: 'Sri Lankan',
                    value: 'Sri Lankan'
                },
                {
                    label: 'Sudanese',
                    value: 'Sudanese'
                },
                {
                    label: 'Surinamese',
                    value: 'Surinamese'
                },
                {
                    label: 'Swazi',
                    value: 'Swazi'
                },
                {
                    label: 'Swedish',
                    value: 'Swedish'
                },
                {
                    label: 'Swiss',
                    value: 'Swiss'
                },
                {
                    label: 'Syrian',
                    value: 'Syrian'
                },
                {
                    label: 'Tajikistani',
                    value: 'Tajikistani'
                },
                {
                    label: 'Tanzanian',
                    value: 'Tanzanian'
                },
                {
                    label: 'Thai',
                    value: 'Thai'
                },
                {
                    label: 'Togolese',
                    value: 'Togolese'
                },
                {
                    label: 'Tokelauan',
                    value: 'Tokelauan'
                },
                {
                    label: 'Tongan',
                    value: 'Tongan'
                },
                {
                    label: 'Trinidadian',
                    value: 'Trinidadian'
                },
                {
                    label: 'Tunisian',
                    value: 'Tunisian'
                },
                {
                    label: 'Turkish',
                    value: 'Turkish'
                },
                {
                    label: ' Turkmen',
                    value: ' Turkmen'
                },
                {
                    label: 'Tuvaluan',
                    value: 'Tuvaluan'
                },
                {
                    label: 'Ugandan',
                    value: 'Ugandan'
                },
                {
                    label: 'Ukrainian',
                    value: 'Ukrainian'
                },
                {
                    label: 'Emirati',
                    value: 'Emirati'
                },
                {
                    label: 'American',
                    value: 'American'
                },
                {
                    label: 'Uruguayan',
                    value: 'Uruguayan'
                },
                {
                    label: 'Uzbekistani',
                    value: 'Uzbekistani'
                },
                {
                    label: 'Ni-Vanuatu',
                    value: 'Ni-Vanuatu'
                },
                {
                    label: 'Vatican',
                    value: 'Vatican'
                },
                {
                    label: 'Venezuelan',
                    value: 'Venezuelan'
                },
                {
                    label: 'Vietnamese',
                    value: 'Vietnamese'
                },
                {
                    label: 'Yemeni',
                    value: 'Yemeni'
                },
                {
                    label: 'Zambian',
                    value: 'Zambian'
                },
                {
                    label: 'Zimbabwean',
                    value: 'Zimbabwean'
                }
            ],
        };
    }
};
