<template>
  <div class="mb-12">
    <b-row>
      <b-col md="5">
        <Amount
            :label="form.commitmentType"
            v-model="form.commitmentAmount"
            :errors-post="errorsPost.commitmentAmount"
            :object-id="objectId"
            :no-save="noSave"
        />
      </b-col>
      <b-col md="7">
        <Responsibility
            v-model="form.clients"
            :errors-post="errorsPost.clients"
            :object-id="objectId"
            :no-save="noSave"
            :other="form.owner_other"
            @other="form.owner_other=$event"
        />
      </b-col>
    </b-row>

    <ResponsibilityOtherDetails
        v-if="form.owner_other"
        v-model="form.owner_other_details"
        :errors-post="errorsPost.owner_other_details"
        :object-id="objectId"
        :no-save="noSave"
    />

  </div>
</template>

<script>

import Responsibility from "../../../question/questions/clientCommitment/Responsibility";
import ResponsibilityOtherDetails from "../../../question/questions/clientCommitment/ResponsibilityOtherDetails";
import Amount from "../../../question/questions/clientCommitment/Amount";

export default {
  name: 'CommitmentShort',
  components: {
    Amount,
    ResponsibilityOtherDetails, 
    Responsibility
  },
  props: {
    value: {
      type: Object,
      required: true
    },
    objectId: {
      type: Number,
      required: false
    },
    noSave: {
      type: Boolean,
      default: false
    },
    errorsPost: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  computed: {
    form: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    }
  }
}
</script>