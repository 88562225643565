<template>
  <QuestionBase
      :question="question"
      :errors="[...errorsPost, ...serverErrors, ...jsErrors]"
      :valid="data !== null"
      :disabled-valid="false">
    <InputSelect
        v-model="data"
        :options="incomeEmployedContractTypeOptions"
        :db="db"
        :args="args"
        :skipped="skipped"
        :errors-post="errorsPost"
        :no-save="noSave"
        :js-errors="jsErrors"
        @skipped="skipped=$event"
        @serverErrors="serverErrors=$event"
        @jsErrors="jsErrors=$event"
    />
  </QuestionBase>
</template>

<script>
import {questionLoadHelpers} from "../../helpers/questionLoadHelpers";
import {incomeOptions} from "../../options/incomeOptions";

import QuestionBase from "../../QuestionBase";
import InputSelect from "../../inputs/InputSelect";

export default {
  name: 'ContractType',
  mixins: [questionLoadHelpers, incomeOptions],
  components: {
    InputSelect,
    QuestionBase
  },
  props: {
    value: {
      type: String,
      required: false
    },
    errorsPost: {
      type: Array,
      default: () => {
        return []
      }
    },
    noSave: {
      type: Boolean,
      default: false
    },
    objectId: {
      type: Number,
      required: false
    },
  },
  computed: {
    data: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    }
  },
  data() {
    return {
      question: {
        title: 'Contract Type',
        subTitle: '',
        tip: null
      },
      db: {
        saveLocation: 'client_income',
        saveField: 'incomeEmployedContractType',
        objectId: this.objectId
      },
      args: {
        taggable: true,
        label: null,
        placeholder: 'Please select..',
        skippable: false,
        required: true,
        inputClass: 'field-75'
      },
      serverErrors: [],
      jsErrors: [],
      skipped: false,
    }
  }
}
</script>
