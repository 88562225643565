<template>
  <QuestionBase :question="question"  :errors="[...jsErrors]" :valid="!!data.length" :id="db.saveField">
    <transition name="fade">
      <div class="accordion sub-items">

        <ObjectCard
            v-for="(asset, index) in data"
            v-model="data[index]"
            :ref="'asset_other' + index"
            :id="'asset_other' + index"
            :key="'asset_other' + asset.id"
            :title="`${asset.assetType}`"
            type="asset-other"
            :loading="loading"
            @delete="deleteObject(asset.id)"
            @save="saveObject(null, 'asset_other' + index)"
        >
          <Component :is="formType" v-model="data[index]" :objectId="asset.id" :key="'asset-form' + asset.id"/>
        </ObjectCard>

        <ObjectCard
            v-if="form"
            v-model="form"
            type="asset-add"
            title="New asset"
            :loading="loading"
            @delete="form=null"
            @save="saveObject(form, db.saveField)"
        >
          <Component :is="formType" v-model="form" :noSave="true" :errors-post="errorsPost"/>
        </ObjectCard>

        <b-button
            v-if="!form"
            @click="addAsset"
            class="btn-question w-100"
        >
          <i class="i-Home1 text-25 font-weight-800 mr-2">
          </i>
          Add Asset
        </b-button>
      </div>
    </transition>
  </QuestionBase>

</template>

<script>
import {objectSaveHelpers} from "../../objects/objectSaveHelpers";
import {questionLoadHelpers} from "../../../helpers/questionLoadHelpers";
import {blankForms} from "../../../helpers/blankForms";

import QuestionBase from "../../../QuestionBase";
import ObjectCard from "../../objects/ObjectCard";
import AssetFull from "../../../../fieldsets/sub/asset/AssetFull";
import AssetMortgage from "../../../../fieldsets/sub/asset/AssetMortgage";
import AssetEstatePlanning from "../../../../fieldsets/sub/asset/AssetEstatePlanning";

export default {
  name: 'AssetsOther',
  components: {AssetFull, ObjectCard, QuestionBase, AssetMortgage, AssetEstatePlanning},
  mixins: [objectSaveHelpers, questionLoadHelpers, blankForms],
  props: {
    value: {
      type: Array,
      required: false
    },
    type: {
      type: String,
      default: 'main'
    },
    title: {
      type: String,
      default: 'Assets'
    },
    subTitle: {
      type: String,
      default: 'Please add a few details for each of your assets.',
    },
    tip: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      question: {
        title: this.title,
        subTitle: this.subTitle,
        tip: this.tip,
      },
      db: {
        saveLocation: 'client_asset',
        saveField: 'assets_other',
        deleteLocation: 'client_asset',
        loadLocation: `client_assets_other_${this.type}`,
        syncMultipleObjectOnSave: true,
        fetchObjectsOnSave: false
      },
      args: {
        skippable: false,
        required: true,
      },
      serverErrors: [],
      errorsPost: {},
      jsErrors: [],
      skipped: false,
      form: null,
      errors: {}
    }
  },
  computed: {
    data: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    },
    formType() {
      if (this.type === 'mortgage') return 'AssetMortgage'
      if (this.type === 'estate_planning') return 'AssetEstatePlanning'
      return 'AssetFull'
    }
  },
  methods: {
    addAsset() {
      this.form = null
      this.form = Object.assign({}, this.assetBlankForm)
    },
  }
}
</script>
