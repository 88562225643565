<template>
  <div>
    <Type
        v-if="!disabledIncomeInput"
        v-model="form.incomeType"
        :errors-post="errorsPost.incomeType"
        :object-id="objectId"
        :no-save="noSave"
    />

    <Responsibility
        v-if="!disabledIncomeClients"
        v-model="form.clients"
        :errors-post="errorsPost.clients"
        :object-id="objectId"
        :no-save="noSave"
    />

    <Employed
        v-if="form.incomeType === 'Employed'"
        v-model="form"
        :objectId="objectId"
        :noSave="noSave"
        :errorsPost="errorsPost"
    />

    <CompanyDirector
        v-if="form.incomeType === 'Company Director'"
        v-model="form"
        :objectId="objectId"
        :noSave="noSave"
        :errorsPost="errorsPost"
    />

    <Contractor
        v-if="form.incomeType === 'Contractor'"
        v-model="form"
        :objectId="objectId"
        :noSave="noSave"
        :errorsPost="errorsPost"
    />

    <SoleTrader
        v-if="form.incomeType === 'Sole Trader'"
        v-model="form"
        :objectId="objectId"
        :noSave="noSave"
        :errorsPost="errorsPost"
    />

    <Partnership
        v-if="form.incomeType === 'Partnership'"
        v-model="form"
        :objectId="objectId"
        :noSave="noSave"
        :errorsPost="errorsPost"
    />

    <limited-liability-partnership
        v-if="form.incomeType === 'Limited Liability Partnership'"
        v-model="form"
        :objectId="objectId"
        :noSave="noSave"
        :errorsPost="errorsPost"
    />

    <ChildMaintenance
        v-if="form.incomeType === 'Child Maintenance'"
        v-model="form"
        :objectId="objectId"
        :noSave="noSave"
        :errorsPost="errorsPost"
    />

    <Benefits
        v-if="form.incomeType === 'Benefits'"
        v-model="form"
        :objectId="objectId"
        :noSave="noSave"
        :errorsPost="errorsPost"
    />

    <Investments
        v-if="form.incomeType === 'Investment'"
        v-model="form"
        :objectId="objectId"
        :noSave="noSave"
        :errorsPost="errorsPost"
    />

    <Pension
        v-if="form.incomeType === 'Pension'"
        v-model="form"
        :objectId="objectId"
        :noSave="noSave"
        :errorsPost="errorsPost"
    />

    <Rental
        v-if="form.incomeType === 'Rental'"
        v-model="form"
        :objectId="objectId"
        :noSave="noSave"
        :errorsPost="errorsPost"
    />

    <Other
        v-if="form.incomeType === 'Other'"
        v-model="form"
        :objectId="objectId"
        :noSave="noSave"
        :errorsPost="errorsPost"
    />



  </div>
</template>

<script>

import Responsibility from "@/components/common/questionnaires/question/questions/clientIncome/Responsibility";
import Type from "@/components/common/questionnaires/question/questions/clientIncome/Type";

import Employed from "@/components/common/questionnaires/fieldsets/sub/income/types/Employed";
import CompanyDirector from "@/components/common/questionnaires/fieldsets/sub/income/types/CompanyDirector";
import Contractor from "@/components/common/questionnaires/fieldsets/sub/income/types/Contractor";
import SoleTrader from "@/components/common/questionnaires/fieldsets/sub/income/types/SoleTrader";
import Partnership from "@/components/common/questionnaires/fieldsets/sub/income/types/Partnership";
import LimitedLiabilityPartnership
  from "@/components/common/questionnaires/fieldsets/sub/income/types/LimitedLiabilityPartnership";
import ChildMaintenance from "@/components/common/questionnaires/fieldsets/sub/income/types/ChildMaintenance";
import Benefits from "@/components/common/questionnaires/fieldsets/sub/income/types/Benefits";
import Investments from "@/components/common/questionnaires/fieldsets/sub/income/types/Investment";
import Pension from "@/components/common/questionnaires/fieldsets/sub/income/types/Pension";
import Rental from "@/components/common/questionnaires/fieldsets/sub/income/types/Rental";
import Other from "@/components/common/questionnaires/fieldsets/sub/income/types/Other";

export default {
  name: 'IncomeFull',
  components: {
    Other,
    Rental,
    Pension,
    Investments,
    Benefits,
    ChildMaintenance,
    LimitedLiabilityPartnership,
    Partnership,
    SoleTrader,
    Contractor,
    Type,
    Responsibility,
    Employed,
    CompanyDirector
  },
  props: {
    value: {
      type: Object,
      required: true
    },
    objectId: {
      type: Number,
      required: false
    },
    noSave: {
      type: Boolean,
      default: false
    },
    errorsPost: {
      type: Object,
      default: () => {
        return {}
      }
    },
    disabledIncomeInput: {
      type: Boolean,
      default: false
    },
    disabledIncomeClients: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    'form.incomeType': {
      deep: false,
      immediate: true,
      handler () {
        this.setFrequency()
      }
    }
  },
  data() {
    return {
      yearlyFrequencyIncomeTypes: [
        'Company Director',
        'Sole Trader',
        'Limited Liability Partnership',
        'Contractor',
        'Employed',
        'Partnership'
      ]
    }
  },
  computed: {
    form: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    }
  },
  methods: {
    setForm() {
      this.setFrequency()
    },
    setFrequency() {
      if (this.yearlyFrequencyIncomeTypes.includes(this.form.incomeType)) {
        this.form.incomeCommonAmountFrequency = 'Yearly';
      }
    }
  }
}
</script>
