<template>
  <div>

    <PreviousAddressMovedInDate v-model="form.addressMovedIn"
                                :no-save="noSave"
                                :object-id="objectId"
                                :errors-post="errorsPost.addressMovedIn || []"/>

    <PreviousAddressOccupancy v-model="form.addressOccupancy"
                              :no-save="noSave"
                              :object-id="objectId"
                              :errors-post="errorsPost.addressOccupancy || []"/>
  </div>
</template>

<script>
import PreviousAddressOccupancy from "../../../question/questions/address/PreviousAddressOccupancy";
import PreviousAddressMovedInDate from "../../../question/questions/address/PreviousAddressMovedInDate";

export default {
  name: 'AddressPrevious',
  components: {
    PreviousAddressMovedInDate,
    PreviousAddressOccupancy
  },
  props: {
    value: {
      type: Object,
      required: true
    },
    objectId: {
      type: Number,
      required: false
    },
    noSave: {
      type: Boolean,
      default: false
    },
    errorsPost: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  computed: {
    form: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    }
  }
}
</script>