export const visaOptions = {
    data () {
        return {
            visaOptions: [
                {
                    label: 'Tier 1 Visa',
                    value: 'Tier 1 Visa'
                },
                {
                    label: 'Tier 2 Visa',
                    value: 'Tier 2 Visa'
                },
                {
                    label: 'Spouse Visa',
                    value: 'Spouse Visa'
                },
                {
                    label: 'Family Visa',
                    value: 'Family Visa'
                },
                {
                    label: 'Pre-Settled Status',
                    value: 'Pre-Settled Status'
                },
                {
                    label: 'Other',
                    value: 'Other'
                }
            ],
        }
    }
}