<template>
  <QuestionBase :question="question" :errors="[...errorsPost, ...serverErrors, ...jsErrors]" :valid="!!data.length" :disabled-valid="true">
    <InputClients
        v-model="data"
        :db="db"
        :skipped="skipped"
        :errors-post="errorsPost"
        :no-save="noSave"
        :js-errors="jsErrors"
        :args="args"
        :showOther="false"
        @skipped="skipped=$event"
        @serverErrors="serverErrors=$event"
        @jsErrors="jsErrors=$event"
    />
  </QuestionBase>
</template>

<script>
import { questionLoadHelpers } from "../../helpers/questionLoadHelpers";

import QuestionBase from "../../QuestionBase";
import InputClients from "../../inputs/InputClients";

export default {
  name: 'Responsibility',
  mixins: [questionLoadHelpers],
  components: {
    InputClients,
    QuestionBase
  },
  props: {
    value: {
      type: Array,
      required: false
    },
    errorsPost: {
      type: Array,
      default: () => {
        return []
      }
    },
    noSave: {
      type: Boolean,
      default: false
    },
    objectId: {
      type: Number,
      required: false
    },
    other: {
      type: Boolean,
      required: false
    }
  },
  computed: {
    data: {
      set(value) {
        this.$emit('input', value)
      },
      get () {
        return this.value
      }
    },
    otherData: {
      set(value) {
        this.$emit('other', value)
      },
      get() {
        return this.other
      }
    }
  },
  data () {
    return {
      question: {
        title:  'Who receives this income?',
        subTitle: 'This income may be shared with your partner.',
        tip: null
      },
      db: {
        saveLocation: 'client_income',
        saveField: 'clients',
        objectId: this.objectId
      },
      args: {
        skippable: false,
        required: false,
      },
      serverErrors: [],
      jsErrors: [],
      skipped: false,
    }
  }
}
</script>
