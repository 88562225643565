<template>
  <QuestionBase :question="question" :errors="[...errorsPost, ...serverErrors, ...jsErrors]" :valid="!!data" :disabled-valid="true">
    <InputTextArea
        v-model="data"
        :db="db"
        :args="args"
        :skipped="skipped"
        :errors-post="errorsPost"
        :no-save="noSave"
        @skipped="skipped=$event"
        @serverErrors="serverErrors=$event"
        @jsErrors="jsErrors=$event"
    />
  </QuestionBase>
</template>

<script>
import { questionLoadHelpers } from "../../helpers/questionLoadHelpers";

import QuestionBase from "../../QuestionBase";
import InputTextArea from "../../inputs/InputTextArea";

export default {
  name: 'Notes',
  mixins: [questionLoadHelpers],
  components: {
    InputTextArea,
    QuestionBase
  },
  props: {
    value: {
      type: String,
      required: false
    },
    errorsPost: {
      type: Array,
      default: () => {
        return []
      }
    },
    noSave: {
      type: Boolean,
      default: false
    },
    objectId: {
      type: Number,
      required: false
    },

  },
  computed: {
    data: {
      set(value) {
        this.$emit('input', value)
      },
      get () {
        return this.value
      }
    }
  },
  data () {
    return {
      question: {
        title:  'Add any relevant notes about this policy:',
        subTitle:  null,
        tip: null
      },
      db: {
        saveLocation: 'client_policy',
        saveField: 'policyNotes',
        objectId: this.objectId
      },
      args: {
        placeholder: 'e.g. This policy has an exclusion for cancer.',
        label: null,
        rows: 4,
        maxRows: 4,
        skippable: false,
        required: false,
        inputClass: 'field-100'
      },
      serverErrors: [],
      jsErrors: [],
      skipped: false,
    }
  }
}
</script>