<template>
  <div class="fieldset">
    <h3 class="fieldset-heading">Contact Information</h3>
    <MobileNumber v-model="form.profileMobileNumber" />
    <Email v-model="form.profileEmail" />
  </div>
</template>

<script>
import { fieldsetHelpers } from "../fieldsetHelpers";

import MobileNumber from "../../question/questions/clientProfile/contact/MobileNumber";
import Email from "../../question/questions/clientProfile/contact/Email";

export default {
  name: 'ContactMortgage',
  mixins: [fieldsetHelpers],
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  components: {
    Email,
    MobileNumber
  },
  data () {
    return {
      label: 'Contact Details', // used for steps progress
      videoUrl: 'https://dm-kernel-public.s3.eu-west-2.amazonaws.com/static/video/kernel_help/contact_details.mp4'

    }
  },
  computed: {
    form: {
      set(value) {
        this.$emit('input', value)
      },
      get () {
        return this.value
      }
    }
  }
};
</script>
