<template>
  <div class="question-multipart-wrapper d-flex flex-wrap align-items-end">
    <QuestionBase :errors="[...serverErrors, ...jsErrors]"
                  :question="question"
                  :valid="!!data">
      <InputDateSplit v-if="data || data === null"
                      v-model="data"
                      :args="args"
                      :db="db"
                      :no-save="noSave"
                      :skipped="skipped"
                      @jsErrors="jsErrors=$event"
                      @serverErrors="serverErrors=$event"
                      @skipped="skipped=$event" />
    </QuestionBase>
  </div>
</template>

<script>
import {questionLoadHelpers} from "../../helpers/questionLoadHelpers";
import {saveHelpers} from "@/views/questionnaires/saveHelpers";

import QuestionBase from "../../QuestionBase";
import InputDateSplit from "../../inputs/InputDateSplit";

export default {
  name: 'StartDate',
  components: {
    InputDateSplit,
    QuestionBase
  },
  mixins: [
    saveHelpers,
    questionLoadHelpers
  ],
  props: {
    value: {
      type: String,
      required: false
    },
    noSave: {
      type: Boolean,
      default: false
    },
    objectId: {
      type: Number,
      required: false
    },
    title: {
      // question title override
      type: String,
      required: false
    }
  },
  computed: {
    data: {
      set(value) {
        this.$emit('input',
            value)
      },
      get() {
        return this.value
      }
    }
  },
  data() {
    return {
      question: {
        title: 'When did the policy start?',
        tip: null,
        subTitle: 'If you cannot remember exactly, an approximate date will suffice.'
      },
      db: {
        saveLocation: 'client_policy',
        saveField: 'policyStartDate',
        objectId: this.objectId
      },
      args: {
        skippable: true,
        required: true,
      },
      serverErrors: [],
      jsErrors: [],
      skipped: false,
    }
  }
}
</script>