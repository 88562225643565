<template>
  <div>

    <Type
        v-model="form.liabilityType"
        :errors-post="errorsPost.liabilityType"
        :object-id="objectId"
        :no-save="noSave"
    />

    <Balance
        v-model="form.liabilityBalance"
        :errors-post="errorsPost.liabilityBalance"
        :object-id="objectId"
        :no-save="noSave"
    />

    <Payment
        v-model="form.liabilityPayment"
        :errors-post="errorsPost.liabilityPayment"
        :object-id="objectId"
        :no-save="noSave"
    />

    <Responsibility
        v-model="form.clients"
        :errors-post="errorsPost.clients"
        :object-id="objectId"
        :no-save="noSave"
        :other="form.owner_other"
        @other="form.owner_other=$event"
    />

    <ResponsibilityOtherDetails
        v-if="form.owner_other"
        v-model="form.owner_other_details"
        :errors-post="errorsPost.owner_other_details"
        :object-id="objectId"
        :no-save="noSave"
    />

    <Lender
        v-model="form.liabilityLender"
        :errors-post="errorsPost.liabilityLender"
        :object-id="objectId"
        :no-save="noSave"
    />

    <InterestRate
        v-model="form.liabilityInterestRate"
        :errors-post="errorsPost.liabilityInterestRate"
        :object-id="objectId"
        :no-save="noSave"
    />

    <InterestRateType
        v-model="form.liabilityInterestRateType"
        :errors-post="errorsPost.liabilityInterestRateType"
        :object-id="objectId"
        :no-save="noSave"
    />

    <RepaymentType
        v-model="form.liabilityRepaymentType"
        :errors-post="errorsPost.liabilityRepaymentType"
        :object-id="objectId"
        :no-save="noSave"
    />

    <ProductEndDateMortgage
        v-if="['Mortgage', 'Mortgage (BTL)'].includes(form.liabilityType)"
        v-model="form.liabilityProductEndDate"
        :errors-post="errorsPost.liabilityProductEndDate"
        :object-id="objectId"
        :no-save="noSave"
    />

    <SecurityAddressMortgage
        v-model="form.security_address"
        :errors-post="errorsPost.security_address"
        :object-id="objectId"
        :no-save="noSave"
        :form="form"
    />

    <RemainingTerm
        :value-remaining-term-years="form.liabilityRemainingTerm"
        :value-remaining-term-months="form.liabilityRemainingTermMonths"
        :errors-post-years="errorsPost.liabilityRemainingTerm"
        :errors-post-months="errorsPost.liabilityRemainingTermMonths"
        :object-id="objectId"
        :no-save="noSave"
        @inputRemainingTermYears="form.liabilityRemainingTerm=$event"
        @inputRemainingTermMonths="form.liabilityRemainingTermMonths=$event"
    />

    <Notes
        v-model="form.liabilityNotes"
        :errors-post="errorsPost.liabilityNotes"
        :object-id="objectId"
        :no-save="noSave"
    />

  </div>
</template>

<script>
import Responsibility from "../../../question/questions/clientLiability/Responsibility";
import ResponsibilityOtherDetails from "../../../question/questions/clientAsset/OwnerOtherDetails";
import Balance from "../../../question/questions/clientLiability/Balance";
import Payment from "../../../question/questions/clientLiability/Payment";
import Lender from "../../../question/questions/clientLiability/Lender";
import InterestRate from "../../../question/questions/clientLiability/InterestRate";
import InterestRateType from "../../../question/questions/clientLiability/InterestRateType";
import RepaymentType from "../../../question/questions/clientLiability/RepaymentType";
import ProductEndDateMortgage from "../../../question/questions/clientLiability/ProductEndDateMortgage";
import SecurityAddressMortgage from "../../../question/questions/clientLiability/SecurityAddressMortgage";
import RemainingTerm from "../../../question/questions/clientLiability/RemainingTerm";
import Notes from "../../../question/questions/clientLiability/Notes";
import Type from "../../../question/questions/clientLiability/Type";

export default {
  name: 'LiabilityOtherFull',
  components: {
    Type,
    Notes,
    RemainingTerm,
    SecurityAddressMortgage,
    ProductEndDateMortgage,
    RepaymentType,
    InterestRateType, InterestRate, Lender, Payment, Balance, ResponsibilityOtherDetails, Responsibility},
  props: {
    value: {
      type: Object,
      required: true
    },
    objectId: {
      type: Number,
      required: false
    },
    noSave: {
      type: Boolean,
      default: false
    },
    errorsPost: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  computed: {
    form: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    }
  }
}
</script>
